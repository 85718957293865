import * as React from "react";

function SvgMember(props) {
  return (
    <svg viewBox="0 0 22 15.174" {...props}>
      <path
        d="M3.952 15.174a7.147 7.147 0 014.015-5.981l.592-.274-.507-.4A4.759 4.759 0 0110.99 0a4.793 4.793 0 014.776 4.819 4.744 4.744 0 01-1.838 3.74l-.508.4.592.274a7.057 7.057 0 014.015 5.939zm17.181-1.543a4.618 4.618 0 00-3.909-4.247v-.93a2.708 2.708 0 001.881-2.6 2.756 2.756 0 00-2.261-2.685c.021-.274.063-.633.105-.865a3.6 3.6 0 011.8 6.233l-.3.254.36.169A5.769 5.769 0 0122 13.631zM0 13.631a5.681 5.681 0 013.191-4.67l.359-.169-.3-.254A3.6 3.6 0 015.051 2.3c.021.252.063.612.106.865a2.73 2.73 0 00-.381 5.284v.93a4.638 4.638 0 00-3.91 4.247z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMember;
