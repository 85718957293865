import * as React from "react";

function SvgDisable(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M11.98 0A12 12 0 1024 11.99 12.017 12.017 0 0011.98 0zm10.027 11.99a9.886 9.886 0 01-1.836 5.768L6.237 3.812a10 10 0 0115.77 8.178zm-20.033 0A9.947 9.947 0 014.737 5.1l14.151 14.159A9.931 9.931 0 0112 22.025 10.045 10.045 0 011.974 11.99z"
        fill="rgba(210,33,49,0.8)"
      />
    </svg>
  );
}

export default SvgDisable;

