import React, { useState, useContext } from "react";
import {
  Outlet,
  Link,
  useOutletContext,
  useLocation,
  useNavigate,
  useLoaderData,
} from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DoneIcon from "@mui/icons-material/Done";
import LinkIcon from "@mui/icons-material/Link";
import Badge from "@mui/material/Badge";
import ButtonBase from "@mui/material/ButtonBase";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import linkifyHtml from "linkify-html";
import mediumZoom from "medium-zoom";
import Toastify from "toastify-js";
import axios from "axios";
import { filesize } from "filesize";

import SvgDelivery from "./SvgDelivery";
import SvgForm from "./SvgForm";
import SvgMaterial from "./SvgMaterial";
import SvgMember from "./SvgMember";
import SvgMessage from "./SvgMessage";

import {
  Centering,
  FlexGrow,
  TopMargin,
  UserIcon,
  InitialsIcon,
} from "./component";
import {
  MessageInput,
  SearchMemberForm,
  InviteMembersForm,
  MetaForm,
} from "./form";
import { mDate, organizationName, yDate, nl2br, requesterName } from "./module";
import { ProgramContext } from "./context";

function ImageZoom({ zoom, src, alt, background }) {
  const zoomRef = React.useRef(zoom.clone({ background }));

  const attachZoom = (image) => {
    zoomRef.current.attach(image);
  };

  return (
    <Image src={src} alt={alt} ref={attachZoom} width="auto" height="80" />
  );
}

const TabTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  font-size: 1.1rem;
  color: #00b3cd;
`;

const ProgramCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 86%;
  height: 6rem;
  margin: 0 auto 1.5rem;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const ProgramCardBottom = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
`;

export function ProgramCard(props) {
  const { user, program } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const requester = requesterName(program);
  const date = yDate(program.createdAt);

  const notificationCntMap = {
    messages: program[`messagesNotificationCount`],
    materials: program[`materialsNotificationCount`],
    deliveries: program[`deliveriesNotificationCount`],
    members: program[`membersNotificationCount`],
    form: program[`formNotificationCount`],
  };

  const handleClick = () => {
    navigate(`/programs/${program.uuid}/timelines${location.search}`);
  };

  return (
    <ProgramCardWrapper onClick={handleClick}>
      <ProgramMenu
        user={user}
        program={program}
        notificationCntMap={notificationCntMap}
      />
      <Typography variant="body2">{program.title}</Typography>
      <FlexGrow />
      <ProgramCardBottom>
        <div>{requester}</div>
        <div>{date}</div>
      </ProgramCardBottom>
    </ProgramCardWrapper>
  );
}

const ProgramIconLinkWrapper = styled.div`
  position: relative;
`;

const ProgramMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
`;

export function ProgramMenu(props) {
  const {
    user,
    program,
    notificationCntMap,
    setNotificationCntMap,
    baseColor = "#00b3cd",
    activeProgram,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname;
  const baseUrl = `/programs/${program.uuid}`;
  const selectedColor = "#0096CC";

  const createIconHandleClick = (type) => {
    let url;
    if (pathname.startsWith(`${baseUrl}/${type}`)) {
      url = `${baseUrl}/timelines${location.search}`;
    } else {
      url = `${baseUrl}/${type}${location.search}`;
    }
    navigate(url);

    if (typeof setNotificationCntMap !== "undefined") {
      const newValue = { ...notificationCntMap };
      newValue[type] = 0;
      setNotificationCntMap(newValue);
    }

    axios.put(`/api/programs/${program.uuid}/${type}/read`);
  };

  return (
    <ProgramMenuWrapper>
      <ProgramStatus
        user={user}
        program={program}
        activeProgram={activeProgram}
      />
      <ProgramIconLinkWrapper>
        <IconButton
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            createIconHandleClick("messages");
          }}
          size="small"
        >
          <Badge
            badgeContent={notificationCntMap[`messages`]}
            color="secondary"
          >
            <SvgMessage
              height="24px"
              color={
                pathname.startsWith(`${baseUrl}/messages`)
                  ? selectedColor
                  : baseColor
              }
            />
          </Badge>
        </IconButton>
      </ProgramIconLinkWrapper>
      <ProgramIconLinkWrapper>
        <IconButton
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            createIconHandleClick("materials");
          }}
          size="small"
        >
          <Badge
            badgeContent={notificationCntMap[`materials`]}
            color="secondary"
          >
            <SvgMaterial
              height="24px"
              color={
                pathname.startsWith(`${baseUrl}/materials`)
                  ? selectedColor
                  : baseColor
              }
            />
          </Badge>
        </IconButton>
      </ProgramIconLinkWrapper>
      <ProgramIconLinkWrapper>
        <IconButton
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            createIconHandleClick("deliveries");
          }}
          size="small"
        >
          <Badge
            badgeContent={notificationCntMap[`deliveries`]}
            color="secondary"
          >
            <SvgDelivery
              height="24px"
              color={
                pathname.startsWith(`${baseUrl}/deliveries`)
                  ? selectedColor
                  : baseColor
              }
            />
          </Badge>
        </IconButton>
      </ProgramIconLinkWrapper>
      <ProgramIconLinkWrapper>
        <IconButton
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            createIconHandleClick("members");
          }}
          size="small"
        >
          <Badge badgeContent={notificationCntMap[`members`]} color="secondary">
            <SvgMember
              height="24px"
              color={
                pathname.startsWith(`${baseUrl}/members`)
                  ? selectedColor
                  : baseColor
              }
            />
          </Badge>
        </IconButton>
      </ProgramIconLinkWrapper>
      <ProgramIconLinkWrapper>
        <IconButton
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            createIconHandleClick("form");
          }}
          size="small"
        >
          <Badge badgeContent={notificationCntMap[`form`]} color="secondary">
            <SvgForm
              height="24px"
              color={
                pathname.startsWith(`${baseUrl}/form`)
                  ? selectedColor
                  : baseColor
              }
            />
          </Badge>
        </IconButton>
      </ProgramIconLinkWrapper>
    </ProgramMenuWrapper>
  );
}

const ProgramStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  height: 1.5rem;
  font-size: 0.8rem;
  color: ${(props) => props.color};
  border: ${(props) => props.border};
  background-color: ${(props) => props.bg};
`;
const labelMap = {
  "-": "-",
  in_progress: "進行中",
  complete: "作業終了",
  archive: "アーカイブ",
  internally: "社内用",
};

const backgroundColorMap = {
  "-": "#fff",
  in_progress: "#F5A33B",
  complete: "rgba(0, 0, 0, 0.25)",
  archive: "rgba(0, 0, 0, 0.5)",
  internally: "#EB6D9A",
};

const borderMap = {
  "-": "1px solid #000",
  in_progress: "none",
  complete: "none",
  archive: "none",
  internally: "none",
};

const colorMap = {
  "-": "#000",
  in_progress: "#fff",
  complete: "#fff",
  archive: "#fff",
  internally: "#fff",
};

export function ProgramStatus(props) {
  const { user, activeProgram } = props;
  const [program, setProgram] = useContext(ProgramContext);
  const { status } = program;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createHandleChange = (v) => () => {
    axios
      .put(`/api/programs/${program.uuid}/updateStatus`, { status: v })
      .then((response) => {
        if (response.data) {
          setProgram(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setAnchorEl(null);
      });
  };

  if (activeProgram && user.type === "operator") {
    return (
      <>
        <ButtonBase
          aria-controls="program-status-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <ProgramStatusWrapper
            bg={backgroundColorMap[status]}
            border={borderMap[status]}
            color={colorMap[status]}
          >
            {labelMap[status]}
          </ProgramStatusWrapper>
        </ButtonBase>
        <Menu
          id="program-status-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={createHandleChange("-")}>-</MenuItem>
          <MenuItem onClick={createHandleChange("in_progress")}>
            進行中
          </MenuItem>
          <MenuItem onClick={createHandleChange("complete")}>作業終了</MenuItem>
          <MenuItem onClick={createHandleChange("archive")}>
            アーカイブ
          </MenuItem>
          <MenuItem onClick={createHandleChange("internally")}>社内用</MenuItem>
        </Menu>
      </>
    );
  } else {
    return (
      <ProgramStatusWrapper
        bg={backgroundColorMap[status]}
        border={borderMap[status]}
        color={colorMap[status]}
      >
        {labelMap[status]}
      </ProgramStatusWrapper>
    );
  }
}

const ProgramWrapper = styled.div`
  flex: 1 0 auto;
  background-color: #fff;
  margin-top: ${(props) => (props.activeProgram ? 56 + 44 + "px" : "0")};

  @media (min-width: 0px) and (orientation: landscape) {
    margin-top: ${(props) => (props.activeProgram ? 48 + 44 + "px" : "0")};
  }

  @media (min-width: 600px) {
    margin-top: ${(props) => (props.activeProgram ? 64 + 44 + "px" : "0")};
  }
`;

export function ViewProgram(props) {
  const { user, program } = props;
  return (
    <ProgramWrapper activeProgram={false}>
      <MessageContainerProps
        user={user}
        program={program}
        messages={program.messages}
      />
    </ProgramWrapper>
  );
}

export function ActiveProgram(props) {
  const {
    user,
    program,
    getInputProps,
    handleFileDialog,
    uploadingFilesMessage,
    activeProgram,
  } = props;

  const messages = [];
  const meterialFiles = [];
  const deliveryFiles = [];
  for (const m of program.messages) {
    if (m.type === "text") {
      messages.push(m);
    } else if (m.type === "file" && m.user.type === "customer") {
      meterialFiles.push(m);
    } else if (m.type === "file" && m.user.type !== "customer") {
      deliveryFiles.push(m);
    }
  }

  let contextParams;
  const { pathname } = window.location;
  const regex = /\/programs\/[a-z0-9-]+\/([a-z]+)/;
  const match = pathname.match(regex);
  if (match.length === 2) {
    const type = match[1];
    switch (type) {
      case "timelines":
        contextParams = [
          user,
          program,
          program.messages,
          getInputProps,
          handleFileDialog,
          uploadingFilesMessage,
          activeProgram,
        ];
        break;
      case "messages":
        contextParams = [
          user,
          program,
          messages,
          getInputProps,
          handleFileDialog,
          uploadingFilesMessage,
          activeProgram,
        ];
        break;
      case "materials":
        contextParams = [
          user,
          program,
          meterialFiles,
          uploadingFilesMessage,
          activeProgram,
        ];
        break;
      case "deliveries":
        contextParams = [
          user,
          program,
          deliveryFiles,
          uploadingFilesMessage,
          activeProgram,
        ];
        break;
      case "members":
        contextParams = [user, program];
        break;
      case "form":
        contextParams = [user, program];
        break;
      default:
    }
  }

  return (
    <ProgramWrapper activeProgram={activeProgram}>
      <Outlet context={contextParams} />
    </ProgramWrapper>
  );
}

const AddMembersContainerWrapper = styled.div`
  flex: 1 0 100vh;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem 1rem;
  background-color: #f5f5f5;
`;

const AddMembersRowWrapper = styled.div`
  display: flex;
  height: 3.5rem;
  padding: 0 0.2rem;
  background-color: #fff;
  align-items: center;
`;

const AddMembersRowText = styled.div`
  margin-left: 0.5rem;
  font-size: 0.75rem;
`;

function AddMembersRow(props) {
  const { user, member, handleChange } = props;

  const onChange = () => {
    handleChange(member);
  };

  return (
    <AddMembersRowWrapper>
      <UserIcon user={user} member={member} active />
      <AddMembersRowText>
        {member.organization} {member.name}
      </AddMembersRowText>
      <FlexGrow />
      <Switch
        checked={member.checked}
        onChange={onChange}
        color="primary"
        name="checkedB"
        inputProps={{ "aria-label": "primary checkbox" }}
      />
    </AddMembersRowWrapper>
  );
}

const AddMembersRows = styled.div`
  margin-top: 1.5rem;
`;

export function AddMembersContainer() {
  const [user, program, setData] = useOutletContext();
  const navigate = useNavigate();

  const [members, setMembers] = useState([]);

  const handleSearch = (data) => {
    setMembers(
      data.map((m) => {
        m.checked = false;
        return m;
      })
    );
  };

  const handleChange = (user) => {
    const newMembers = [];
    members.forEach((member) => {
      if (member.uuid === user.uuid) {
        member.checked = !member.checked;
      }
      newMembers.push(member);
    });
    setMembers(newMembers);
  };

  const handleAdd = () => {
    const uuids = [];
    members.forEach((m) => {
      if (m.checked) {
        uuids.push(m.uuid);
      }
    });
    axios
      .post(`/api/programs/${program.uuid}/addMembers`, {
        userUuids: uuids,
      })
      .then(function (response) {
        if (response.data) {
          setData(response.data);
          navigate(-1);
        }
      })
      .catch(function (error) {
        if (error.response) {
        }
      })
      .finally(function () {});
  };

  let disabled = true;
  members.forEach((m) => {
    if (m.checked) {
      disabled = false;
    }
  });

  return (
    <AddMembersContainerWrapper>
      <SearchMemberForm program={program} onSearch={handleSearch} />
      <AddMembersRows>
        {members.map((member) => (
          <AddMembersRow
            key={member.uuid}
            user={user}
            member={member}
            handleChange={handleChange}
          />
        ))}
      </AddMembersRows>
      {members.length > 0 && (
        <TopMargin marginTop={1.0}>
          <Centering>
            <Button
              type="button"
              variant="contained"
              size="large"
              color="primary"
              disabled={disabled}
              onClick={handleAdd}
            >
              SAVE
            </Button>
          </Centering>
        </TopMargin>
      )}
    </AddMembersContainerWrapper>
  );
}

const MemberContainerLabel = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.color};
  margin-top: 2rem;
  margin-left: 0.4rem;
`;

const MemberContainerInviteLinkWrapper = styled.div`
  display: flex;
  color: #00b3cd;
  align-items: center;
  height: 2rem;
  position: absolute;
  top: 0;
  right: 1rem;
`;

function MemberContainerInviteLink(props) {
  const { user, program, setData } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (data) => {
    setOpen(false);
    setData(data);
  };

  return (
    <>
      <MemberContainerInviteLinkWrapper onClick={handleClickOpen}>
        招待&nbsp;&nbsp;
        <SendIcon />
      </MemberContainerInviteLinkWrapper>
      <InviteMembersForm
        user={user}
        program={program}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
}

const MemberContainerWrapper = styled.div`
  position: relative;
`;

export function MemberContainer() {
  const [user, program] = useOutletContext();
  const loadedData = useLoaderData();
  const [data, setData] = useState(loadedData);
  const { members, partners, salmixUsers } = data;

  return (
    <>
      <Outlet context={[user, program, setData]} />
      <MemberContainerWrapper>
        {(user.type === "operator" || program.isRequester) && (
          <MemberContainerInviteLink
            user={user}
            program={program}
            setData={setData}
          />
        )}
        <MemberContainerLabel color="#6EBA44">Member</MemberContainerLabel>
        <MemberIconList
          user={user}
          program={program}
          users={members}
          listType="customer"
          setData={setData}
        />
        <MemberContainerLabel color="#E1A245">PARTNER</MemberContainerLabel>
        <MemberIconList
          user={user}
          program={program}
          users={partners}
          listType="partner"
          setData={setData}
        />
        <MemberContainerLabel color="#EB6D9A">SALMIX</MemberContainerLabel>
        <MemberIconList
          user={user}
          program={program}
          users={salmixUsers}
          areSalmixUsers
          listType="operator"
          setData={setData}
        />
      </MemberContainerWrapper>
    </>
  );
}

const MemberIconListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  justify-items: center;
  row-gap: 0.5rem;
  padding: 0 0.4rem;
  margin-top: 0.75rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(10, 1fr);
  }
`;

function MemberIconList(props) {
  const { user, program, users, color, areSalmixUsers, listType, setData } =
    props;
  let handleClick;

  if (user.type === "operator" && areSalmixUsers) {
    handleClick = (selected) => {
      const newUuids = [];
      users.forEach((u) => {
        if (u.uuid === selected.uuid && !u.active) {
          newUuids.push(u.uuid);
        } else if (u.uuid !== selected.uuid && u.active) {
          newUuids.push(u.uuid);
        }
      });
      axios
        .post(`/api/programs/${program.uuid}/updateSalmixUsers`, {
          userUuids: newUuids,
        })
        .then(function (response) {
          if (response.data) {
            setData(response.data);
          }
        })
        .catch(function (error) {
          if (error.response) {
          }
        })
        .finally(function () {});
    };
  }

  const handleDelete = (user) => {
    axios
      .delete(`/api/programs/${program.uuid}/removeMember/${user.uuid}`)
      .then(function (response) {
        if (response.data) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error.response) {
        }
      })
      .finally(function () {});
  };

  return (
    <MemberIconListWrapper>
      {users.map((u) => (
        <UserIcon
          key={u.uuid}
          program={program}
          user={user}
          member={u}
          showStarMark={u.uuid === program.starUuid}
          active={u.active}
          handleClick={handleClick}
          handleDelete={handleDelete}
        />
      ))}
      {user.type === "operator" && listType === "customer" && (
        <AddMemberIcon program={program} color={color} />
      )}
      {user.type === "operator" && listType === "partner" && (
        <AddPartnerIcon program={program} color={color} />
      )}
    </MemberIconListWrapper>
  );
}

const MessageContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export function MessageContainerProps(props) {
  const { user, program, messages } = props;
  const tabTitle = "";
  return (
    <MessageContainerWrapper>
      <MessageList
        user={user}
        program={program}
        messages={messages}
        tabTitle={tabTitle}
      />
    </MessageContainerWrapper>
  );
}

export function MessageContainer() {
  const [
    user,
    program,
    messages,
    getInputProps,
    handleFileDialog,
    uploadingFilesMessage,
    activeProgram,
  ] = useOutletContext();
  const tabTitle = "";
  return (
    <MessageContainerWrapper>
      <MessageList
        user={user}
        program={program}
        messages={messages}
        uploadingFilesMessage={uploadingFilesMessage}
        activeProgram={activeProgram}
        tabTitle={tabTitle}
      />
      <MessageInput
        user={user}
        program={program}
        getInputProps={getInputProps}
        handleFileDialog={handleFileDialog}
      />
    </MessageContainerWrapper>
  );
}

export function FileContainer() {
  const [user, program, files, uploadingFilesMessage, activeProgram] =
    useOutletContext();
  const tabTitle = "";
  return (
    <MessageContainerWrapper>
      <MessageList
        user={user}
        program={program}
        messages={files}
        uploadingFilesMessage={uploadingFilesMessage}
        activeProgram={activeProgram}
        tabTitle={tabTitle}
      />
    </MessageContainerWrapper>
  );
}

const MessageListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background-color: #f5f5f5;
`;

function MessageList(props) {
  const {
    user,
    program,
    messages,
    uploadingFilesMessage,
    activeProgram,
    tabTitle,
    showImgAsIcon = true,
  } = props;

  const zoom = React.useRef(
    mediumZoom({ background: "#000", margin: 48, zIndex: 2000 })
  );

  return (
    <MessageListWrapper>
      {activeProgram && tabTitle && <TabTitle>{tabTitle}</TabTitle>}
      {messages.map((message) => (
        <Message
          key={message.id}
          program={program}
          user={user}
          message={message}
          zoom={zoom}
          activeProgram={activeProgram}
          showImgAsIcon={showImgAsIcon}
        />
      ))}
      {uploadingFilesMessage ? (
        <Message
          key={uploadingFilesMessage.id}
          program={program}
          user={user}
          message={uploadingFilesMessage}
          activeProgram={activeProgram}
          showImgAsIcon={showImgAsIcon}
        />
      ) : null}
    </MessageListWrapper>
  );
}

const UserIconWrapper = styled.div`
  flex: 0 0 43px;
`;

const MessageWrapper = styled.div`
  display: flex;
  margin: 0.5rem 0;
  padding: 0 0.5rem;
`;

function Message(props) {
  const { user, program, message, zoom, showImgAsIcon, activeProgram } = props;
  return (
    <MessageWrapper>
      <UserIconWrapper>
        <UserIcon user={user} member={message.user} active operatorAsMonkey />
      </UserIconWrapper>
      <MessageBody
        user={user}
        program={program}
        message={message}
        zoom={zoom}
        activeProgram={activeProgram}
        showImgAsIcon={showImgAsIcon}
      />
    </MessageWrapper>
  );
}

const MessageBodyHead = styled.div`
  font-size: 0.75rem;
`;

const MessageBodyText = styled.div`
  margin-top: 0.4rem;
  font-size: 0.8rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const MessageBodyFileList = styled.div`
  margin-top: 0.4rem;
  font-size: 0.8rem;
`;

const MessageBodyFileWrapper = styled.div`
  position: relative;
  font-size: 0.75rem;
  margin-right: 0.5rem;

  &:not(:first-of-type) {
    margin-top: 1.5rem;
  }
`;

function MessageBodyFile(props) {
  const { user, program, zoom, file, activeProgram, showImgAsIcon } = props;

  let downloadUrl;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    downloadUrl = `http://localhost:4000/api/programs/${program.uuid}/files/${file.id}`;
  } else {
    downloadUrl = `/api/programs/${program.uuid}/files/${file.id}`;
  }

  let isImageFile = false;
  let imageUrl;
  const imageContentTypes = ["image/jpeg", "image/png", "image/tiff"];
  if (imageContentTypes.includes(file.contentType)) {
    isImageFile = true;
    imageUrl = `/api/programs/${program.uuid}/images/${file.id}`;
  }

  const handleDownloadLink = () => {
    axios
      .get(`/api/programs/${program.uuid}/files/${file.id}/url`)
      .then(function (response) {
        if (response.data) {
          navigator.clipboard.writeText(response.data);
          Toastify({
            text: "7日間有効なダウンロードリンクが作成され、クリップボードにコピーされました。",
            duration: 3000,
            gravity: "bottom", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            backgroundColor: "#00b3cd",
          }).showToast();
        }
      })
      .catch(function (error) {
        if (error.response) {
        }
      })
      .finally(function () {});
  };

  let ele = null;
  if (file.downloads) {
    if (file.downloads.length > 0) {
      const textDom = file.downloads.map((d) => (
        <div key={d.uuid}>
          {mDate(d.createdAt)}&nbsp;&nbsp;{organizationName(d)}
        </div>
      ));
      ele = (
        <MessageBodyFileIconWrapper>
          <Tooltip title={textDom} placement="top" arrow>
            <DoneIcon />
          </Tooltip>
        </MessageBodyFileIconWrapper>
      );
    }
  } else {
    ele = (
      <>
        <MessageBodyFileProgressBarContainer>
          <MessageBodyFileProgressBarBase />
          <MessageBodyFileProgressBar state={file.state} />
        </MessageBodyFileProgressBarContainer>
        <MessageBodyFileProgressText>{file.state}%</MessageBodyFileProgressText>
      </>
    );
  }

  return (
    <MessageBodyFileWrapper>
      <MessageBodyFileName>
        {file.name} ({filesize(file.size)})
      </MessageBodyFileName>
      {file.downloads && (
        <MessageBodyFileIconWrapper>
          {activeProgram ? (
            <IconButton
              color="inherit"
              size="small"
              href={downloadUrl}
              download={file.name}
            >
              {user.type === "customer" ? (
                <CloudUploadIcon />
              ) : (
                <CloudDownloadIcon />
              )}
            </IconButton>
          ) : (
            <>
              {user.type === "customer" ? (
                <CloudUploadIcon />
              ) : (
                <CloudDownloadIcon />
              )}
            </>
          )}
        </MessageBodyFileIconWrapper>
      )}
      {showImgAsIcon && isImageFile && (
        <>
          {activeProgram ? (
            <ImageZoom src={imageUrl} alt={imageUrl} zoom={zoom.current} />
          ) : (
            <img src={imageUrl} alt={imageUrl} width="auto" height="80" />
          )}
        </>
      )}
      {ele}
      {user.type !== "customer" && activeProgram && file.downloads && (
        <GenDownloadLinkIcon>
          <IconButton color="inherit" size="small" onClick={handleDownloadLink}>
            <LinkIcon />
          </IconButton>
        </GenDownloadLinkIcon>
      )}
    </MessageBodyFileWrapper>
  );
}

const MessageBodyDeleteIconWrapper = styled.div`
  position: absolute;
  right: -0.4rem;
  bottom: -0.4rem;
  transform: scale(0.75);
`;

const MessageBodyFileIconWrapper = styled.div`
  margin-left: 2rem;
  a,
  svg {
    color: #00b3cd;
  }
`;

const MessageBodyFileProgressBarContainer = styled.div`
  position: relative;
  margin-top: 0.5rem;
  width: 4rem;
  height: 0.5rem;
`;

const MessageBodyFileProgressBarBase = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 1.6rem;
  background-color: #fff;
  border: 1px solid #00b3cd;
`;

const MessageBodyFileProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => `${props.state}%`};
  height: inherit;
  background-color: #00b3cd;
  border-radius: 1.6rem;
`;

const MessageBodyFileProgressText = styled.div`
  margin-top: 0.2rem;
  font-size: 0.75rem;
`;

const Image = styled.img`
  margin-top: 0.2rem;
`;

const GenDownloadLinkIcon = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  color: #00b3cd;
`;

const MessageBodyFileName = styled.div`
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const MessageBodyWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  min-width: 0;
  color: #707070;
  padding: 0.4rem 0.5rem;
  margin-left: 0.4rem;
  background-color: ${(props) =>
    props.type === "uploading" ? "none" : "#fff"};
  border: ${(props) =>
    props.type === "uploading" ? "2px dashed #00b3cd" : "none"};
`;

function MessageBody(props) {
  const { message, zoom, activeProgram, showImgAsIcon } = props;
  const [program, setProgram] = useContext(ProgramContext);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    axios
      .delete(`/api/programs/${program.uuid}/messages/${message.id}`)
      .then(function (response) {
        if (response.data) {
          setProgram(response.data);
        }
      })
      .catch(function (error) {
        if (error.response) {
        }
      })
      .finally(function () {});
  };

  let datetime = "";
  if (message.createdAt) {
    datetime = mDate(message.createdAt);
  }

  const htmlMsg = { __html: linkifyHtml(nl2br(message.text)) };

  return (
    <MessageBodyWrapper type={message.type}>
      <MessageBodyHead>
        {message.user.organization} {message.user.name} {datetime}
      </MessageBodyHead>
      {message.type === "text" ? (
        <MessageBodyText dangerouslySetInnerHTML={htmlMsg} />
      ) : (
        <MessageBodyFileList>
          {message.files.map((f) => (
            <MessageBodyFile
              key={f.name}
              user={message.user}
              program={program}
              file={f}
              zoom={zoom}
              activeProgram={activeProgram}
              showImgAsIcon={showImgAsIcon}
            />
          ))}
        </MessageBodyFileList>
      )}
      {activeProgram && message.canDelete ? (
        <MessageBodyDeleteIconWrapper>
          <IconButton color="secondary" size="small" onClick={handleClickOpen}>
            <DeleteIcon />
          </IconButton>
        </MessageBodyDeleteIconWrapper>
      ) : null}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>本当にメッセージを削除しますか？</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </MessageBodyWrapper>
  );
}

function AddMemberIcon(props) {
  const { program } = props;
  const location = useLocation();
  return (
    <Link to={`/programs/${program.uuid}/members/add${location.search}`}>
      <InitialsIcon type="customer">
        <AddIcon />
      </InitialsIcon>
    </Link>
  );
}

function AddPartnerIcon(props) {
  const { program } = props;
  const location = useLocation();
  return (
    <Link to={`/programs/${program.uuid}/members/add${location.search}`}>
      <InitialsIcon type="partner">
        <AddIcon />
      </InitialsIcon>
    </Link>
  );
}

const FormContainerWrapper = styled.div`
  padding: 0.5rem 0.75rem;
`;

export function FormContainer() {
  const [user, program] = useOutletContext();
  const { formFields } = useLoaderData();
  return (
    <FormContainerWrapper>
      <MetaForm user={user} program={program} initialFormFields={formFields} />
    </FormContainerWrapper>
  );
}
