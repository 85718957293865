import * as React from "react";

function SvgMessage(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 17.969" {...props}>
      <path
        d="M18.164 14.219l.368 1.019A4.77 4.77 0 0022 11.011c0-2.384-2.3-4.335-5.267-4.638C16.625 2.839 12.94 0 8.367 0 3.75 0 0 2.9 0 6.5c0 3.034 2.688 5.592 6.307 6.307l-2.233 3.4L8.475 13a11.189 11.189 0 001.929-.2 6.262 6.262 0 005.332 2.883l2.276 2.276.759-.759-2.579-2.623h-.217a5.3 5.3 0 01-4.465-2.059c2.688-.845 4.7-2.774 5.137-5.072 2.406.238 4.27 1.734 4.27 3.555a3.713 3.713 0 01-2.753 3.218zm-13.113-7a.694.694 0 11.694-.694.685.685 0 01-.695.693zm3.338 0a.694.694 0 11.694-.694.685.685 0 01-.695.693zm3.338 0a.694.694 0 11.694-.694.685.685 0 01-.695.693z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMessage;
