import React from "react";
import styled from '@emotion/styled'

import { VerticalCenterBox, TopMargin } from "./component";
import { MyAppBar } from "./appbar";
import { UpdateUserForm, UpdateEmailForm, UpdatePasswordForm } from "./form";

const UpdateUserWrapper = styled.div``;
export function UpdateUser() {
  return (
    <UpdateUserWrapper>
      <MyAppBar label="アカウント変更" />
      <TopMargin marginTop={1} />
      <VerticalCenterBox>
        <UpdateUserForm />
      </VerticalCenterBox>
    </UpdateUserWrapper>
  );
}

const UpdateEmailWrapper = styled.div``;
export function UpdateEmail() {
  return (
    <UpdateEmailWrapper>
      <MyAppBar label="メールアドレス変更" />
      <TopMargin marginTop={1} />
      <VerticalCenterBox>
        <UpdateEmailForm />
      </VerticalCenterBox>
    </UpdateEmailWrapper>
  );
}

const UpdatePasswordWrapper = styled.div``;
export function UpdatePassword() {
  return (
    <UpdatePasswordWrapper>
      <MyAppBar label="パスワード変更" />
      <TopMargin marginTop={1} />
      <VerticalCenterBox>
        <UpdatePasswordForm />
      </VerticalCenterBox>
    </UpdatePasswordWrapper>
  );
}
