import * as React from "react";

function SvgForm(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 22.49" {...props}>
      <path
        d="M0 22.49V0h17.994L18 13.913l-8.577 8.571H8.071v-9.929h8.821V1.108H1.1v20.279h3.97v1.1zM3.069 10.2V9.088h11.862V10.2zm0-2.836v-1.1h11.862v1.1zm0-2.842v-1.1h11.862v1.1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgForm;
