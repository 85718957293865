import * as React from "react";

function SvgMaterial(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20.44" {...props}>
      <path
        d="M0 20.44v-8.12h4.473v1.146H1.147v5.828h19.706v-5.828H17.5V12.32H22v8.12zm7.432-5.07zm7.112 0H7.432V8.236H2.753L10.988 0l8.236 8.236h-4.68z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMaterial;
