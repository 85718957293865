import React, { useContext } from "react";
import { createPortal } from "react-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ListIcon from "@mui/icons-material/List";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import styled from "@emotion/styled";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { styled as styled2 } from "@mui/material/styles";
import linkifyHtml from "linkify-html";

import { FlexGrow, TextLink, UserIcon } from "./component";
import { FilterProgramForm } from "./form";
import { mDate, nl2br } from "./module";
import { ProgramContext } from "./context";

import MailIcon from "./SvgMail";
import DisableIcon from "./SvgDisable";
import Logo from "./logo3.png";

const Logo3Img = styled.img`
  width: 4rem;
  height: auto;
  opacity: 0.9;
`;

const LogoLink = styled.a`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    display: ${(props) => (props.label ? "none" : "block")};
  }
`;

function NotificationList(props) {
  const { user, notifications, setPanel } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const handleNotificationItem = (n) => {
    const url = `/programs/${n.programUuid}/messages${location.search}`;
    setPanel(null);
    navigate(url);
  };

  return (
    <>
      {notifications.map((n) => (
        <NotificationItem
          key={n.id}
          user={user}
          notification={n}
          onClick={handleNotificationItem}
        />
      ))}
    </>
  );
}

const MyInnerAppBar = styled2(AppBar)(() => ({
  backgroundColor: "#00B3CD",
}));

const MyIconButton = styled2(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const NotificationItemLeft = styled.div`
  flex: 0 0 3.5rem;
`;

const NotificationItemRight = styled.div`
  flex: 1 1 auto;
  // trik: https://dev.to/martyhimmel/quick-tip-to-stop-flexbox-from-overflowing-peb
  min-width: 0;
`;

const NotificationItemMessage = styled.div`
  margin-top: 0.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const NotificationItemUserInfo = styled.div`
  margin-top: 0.5rem;
  font-size: 0.75rem;
`;

const NotificationItemWrapper = styled.div`
  display: flex;
  color: #707070;
  font-size: 0.8rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 0.12rem solid #707070;
  overflow-x: hidden;
`;

function NotificationItem(props) {
  const { user, notification, onClick } = props;

  const datetime = mDate(notification.createdAt);

  const handleClick = (e) => {
    e.preventDefault();
    onClick(notification);
  };

  const htmlMsg = { __html: linkifyHtml(nl2br(notification.text)) };

  return (
    <NotificationItemWrapper>
      <NotificationItemLeft>
        <UserIcon user={user} member={notification} active />
      </NotificationItemLeft>
      <NotificationItemRight>
        <TextLink href="#" color="inherit" onClick={handleClick}>
          {notification.programTitle}
        </TextLink>
        <NotificationItemMessage dangerouslySetInnerHTML={htmlMsg} />
        <NotificationItemUserInfo>
          {datetime}&nbsp;&nbsp;{notification.organization}
          &nbsp;&nbsp;
          {notification.name}
        </NotificationItemUserInfo>
      </NotificationItemRight>
    </NotificationItemWrapper>
  );
}

const MailIconWrapper = styled.div`
  position: relative;
`;

const DisableIconStyled = styled(DisableIcon)`
  position: absolute;
`;

export function MailIconWithState() {
  const [program, setProgram] = useContext(ProgramContext);

  const handleClick = (e) => {
    e.stopPropagation();

    axios
      .put(`/api/programs/${program.uuid}/updateEmailSetting`)
      .then((response) => {
        if (response.data) {
          setProgram(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <MailIconWrapper>
      <MyAppBarRight>
        <IconButton color="inherit" onClick={handleClick}>
          <MailIcon />
          {program.emailEnabled ? null : <DisableIconStyled />}
        </IconButton>
      </MyAppBarRight>
    </MailIconWrapper>
  );
}

const ViewIconWrapper = styled.div`
  color: #fff;

  @media (max-width: 767px) {
    display: none;
  }
`;

const SearchIconWrapper = styled.div`
  color: ${(props) => (props.active ? "#fff" : "#fff")};
  opacity: ${(props) => (props.active ? "1.0" : "0.74")};
`;

const NotificationIconWrapper = styled.div`
  color: ${(props) => (props.active ? "#FFF200" : "#fff")};
  opacity: ${(props) => (props.active ? "1.0" : "0.74")};
`;

const bodyDom = document.getElementsByTagName("body")[0];
class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.el.style = `
position: absolute;
top: 4rem;
right: 0.2rem;
bottom: 2rem;
width: 20rem;
padding: 0.5rem 0.75rem;
background-color: #fff;
opacity: 0.95;
z-index: 1200;
overflow-y: auto;
    `;
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    bodyDom.appendChild(this.el);
  }

  componentWillUnmount() {
    bodyDom.removeChild(this.el);
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}

const MyAppBarRight = styled.div`
  display: flex;
`;

const MyAppBarWrapper = styled.div`
  color: #fff;
`;

export function MyAppBar(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    label,
    user,
    notifications,
    setAccount,
    program,
    viewMode,
    isProgramHeader = false,
    onViewMode,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [panel, setPanel] = React.useState(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const createHandleMenuClose = (url) => {
    return () => {
      setAnchorEl(null);
      window.location.href = url;
    };
  };

  const handleSearch = (q, who, status, mine, archived) => {
    const params = new URLSearchParams();
    if (q) {
      params.set("q", q);
    }
    if (who) {
      params.set("who", who);
    }
    if (status) {
      params.set("status", status);
    }
    if (mine) {
      params.set("mine", "true");
    }
    if (archived) {
      params.set("archived", "true");
    }
    const searchParams = params.toString();
    if (searchParams) {
      navigate(`/programs?${searchParams}`);
    } else {
      navigate(`/programs`);
    }
  };

  let panelJsx;
  if (panel === "search") {
    panelJsx = (
      <Panel>
        <FilterProgramForm user={user} onSearch={handleSearch} />
      </Panel>
    );
  } else if (panel === "notification") {
    panelJsx = (
      <Panel>
        <NotificationList
          user={user}
          notifications={notifications}
          setPanel={setPanel}
        />
      </Panel>
    );
  } else {
    panelJsx = null;
  }

  const isMenuOpen = Boolean(anchorEl);
  const menuId = "account-menu";

  const handleSearchIcon = () => {
    if (panel === "search") {
      setPanel(null);
    } else {
      setPanel("search");
    }
  };

  const handleNotificationIcon = () => {
    if (panel === "notification") {
      setPanel(null);
    } else {
      setPanel("notification");
      axios
        .put(`/api/notifications/read`)
        .then((response) => {
          if (response.data) {
            setAccount(response.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  let renderLeftIcon;
  let renderRightIcon;
  if (isProgramHeader) {
    renderLeftIcon = (
      <MyIconButton
        edge="start"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={() => {
          navigate(`/programs${location.search}`);
        }}
        color="inherit"
      >
        <CloseIcon />
      </MyIconButton>
    );
    renderRightIcon = program["isParticipant"] ? <MailIconWithState /> : null;
  } else if (location.pathname.startsWith("/programs")) {
    renderLeftIcon = (
      <MyIconButton
        edge="start"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        size="small"
      >
        <UserIcon user={user} member={user} active handleClick={() => {}} />
      </MyIconButton>
    );
    renderRightIcon = (
      <MyAppBarRight>
        <ViewIconWrapper>
          <IconButton color="inherit" onClick={onViewMode}>
            {viewMode === "list" ? <ViewWeekIcon /> : <ListIcon />}
          </IconButton>
        </ViewIconWrapper>
        <SearchIconWrapper active={location.search !== ""}>
          <IconButton color="inherit" onClick={handleSearchIcon}>
            <SearchIcon />
          </IconButton>
        </SearchIconWrapper>
        <NotificationIconWrapper active={!user.hasReadNotifications}>
          <IconButton color="inherit" onClick={handleNotificationIcon}>
            <NotificationsIcon />
          </IconButton>
        </NotificationIconWrapper>
      </MyAppBarRight>
    );
  } else {
    renderLeftIcon = (
      <MyIconButton
        edge="start"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={() => {
          window.location.href = `/programs${location.search}`;
        }}
        color="inherit"
      >
        <ArrowBackIcon />
      </MyIconButton>
    );
    renderRightIcon = null;
  }

  const renderMenu = (
    <Menu
      id={menuId}
      open={isMenuOpen}
      keepMounted
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MenuItem onClick={createHandleMenuClose("updateUser")}>
        アカウント変更
      </MenuItem>
      <MenuItem onClick={createHandleMenuClose("updateEmail")}>
        メールアドレス変更
      </MenuItem>
      <MenuItem onClick={createHandleMenuClose("updatePassword")}>
        パスワード変更
      </MenuItem>
      <MenuItem
        onClick={async () => {
          await axios.get("/api/logout");
          window.location.href = "/";
        }}
      >
        ログアウト
      </MenuItem>
    </Menu>
  );

  return (
    <MyAppBarWrapper>
      <MyInnerAppBar position="static" color="inherit">
        <Toolbar>
          {renderLeftIcon}
          {isProgramHeader ? (
            <Typography variant="body1" noWrap>
              {program.title}
            </Typography>
          ) : (
            <Typography variant="body1" noWrap>
              {label}
            </Typography>
          )}
          <FlexGrow />
          {renderRightIcon}
          {!isProgramHeader ? (
            <LogoLink href={`/programs${location.search}`} label={label}>
              <Logo3Img src={Logo} />
            </LogoLink>
          ) : null}
        </Toolbar>
      </MyInnerAppBar>
      {renderMenu}
      {panelJsx}
    </MyAppBarWrapper>
  );
}
