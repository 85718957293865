import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import DoneIcon from "@mui/icons-material/Done";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import IconButton from "@mui/material/IconButton";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import styled from "@emotion/styled";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import validator from "validator";
import axios from "axios";
import { isEmpty, findIndex, pick, get } from "lodash";
import { useLocation } from "react-router-dom";

import {
  Centering,
  Rightify,
  TopMargin,
  TextLink,
  Paragraph,
} from "./component";
import { useLocalStorage } from "./module";
import { ProgramContext } from "./context";

const programStatusMap = {
  "": "",
  "-": "-",
  in_progress: "進行中",
  complete: "作業終了",
  archive: "アーカイブ",
  internally: "社内用",
};

const MyField = styled.div`
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}rem` : null)};
  margin-right: ${(props) =>
    props.marginRight ? `${props.marginRight}rem` : null};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}rem` : null};
  margin-left: ${(props) =>
    props.marginLeft ? `${props.marginLeft}rem` : null};
  flex: ${(props) => (props.flex ? `${props.flex}` : null)};
`;

function makeField(args) {
  const {
    name,
    label,
    type = "text",
    fullWidth = true,
    defaultHelperText = undefined,
  } = args;
  return function (props) {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleKeyDown,
      required = false,
      ...another
    } = props;
    const InputProps = { onChange: handleChange, onBlur: handleBlur };
    if (handleKeyDown) {
      InputProps.onKeyDown = handleKeyDown;
    }
    return (
      <MyField {...another}>
        <TextField
          type={type}
          name={name}
          value={values[name]}
          error={errors[name] && touched[name]}
          label={label}
          variant="outlined"
          fullWidth={fullWidth}
          InputProps={InputProps}
          helperText={
            (errors[name] && touched[name] && errors[name]) || defaultHelperText
          }
          required={required}
        />
      </MyField>
    );
  };
}

const EmailField = makeField({ name: "email", label: "E-mail", type: "email" });

const PasswordField = makeField({
  name: "password",
  label: "Password",
  type: "password",
});

const OldPasswordField = makeField({
  name: "oldPassword",
  label: "現在のPassword",
  type: "password",
});

const NewPasswordField = makeField({
  name: "newPassword",
  label: "新しいPassword",
  type: "password",
});

const NameField = makeField({ name: "name", label: "Name" });

const InitialsField = makeField({ name: "initials", label: "アイコン" });

const OrganizationField = makeField({
  name: "organization",
  label: "会社名",
  defaultHelperText: "※ フリーランスの方はフリーランスと入力",
});

const AddressField = makeField({ name: "address", label: "Address" });

const TelField = makeField({ name: "tel", label: "TEL" });

const TitleField = makeField({ name: "title", label: "タイトル" });

const EmailsField = makeField({
  name: "emails",
  label: "招待したいメンバーのEmail",
});

const SearchMemberField = makeField({
  name: "text",
  label: "名前、会社名、Email",
});

const SearchWordField = makeField({
  name: "q",
  label: "プログラム名",
});

const SearchUserField = makeField({
  name: "who",
  label: "名前、会社名",
});

const SignInFormFormik = styled.form`
  margin-top: 2rem;
  width: 92%;
  @media (min-width: 768px) {
    width: 320px;
  }
`;

const SignInFormLinks = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SignInInitialValues = {
  email: "",
  password: "",
};
const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(4, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
});

export const SignInForm = () => {
  const [, updateUser] = useLocalStorage("account");
  return (
    <Formik
      initialValues={SignInInitialValues}
      validationSchema={SignInSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        axios
          .post("/api/u/signIn", values)
          .then(function (res) {
            updateUser(res.data);
            window.location.href = "/programs";
          })
          .catch(function (error) {
            setErrors({
              password: "メールアドレスとパスワードが一致しません。",
            });
          })
          .finally(function () {
            setSubmitting(false);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <SignInFormFormik onSubmit={handleSubmit} noValidate autoComplete="off">
          <EmailField
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <PasswordField
            marginTop={1.2}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <TopMargin marginTop={2.0}>
            <Centering>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isSubmitting}
              >
                SIGN IN
              </Button>
            </Centering>
          </TopMargin>
          <TopMargin marginTop={1.5}>
            <SignInFormLinks>
              <TextLink href="/signUp" color="secondary">
                新規アカウント作成
              </TextLink>
              <TextLink href="/resetPasswordStep1">
                パスワードを忘れた方
              </TextLink>
            </SignInFormLinks>
          </TopMargin>
        </SignInFormFormik>
      )}
    </Formik>
  );
};

const SignUpFormWrapper = styled.div`
  width: 92%;
  @media (min-width: 768px) {
    width: 28rem;
    margin: 0 auto;
    padding: 2rem 0;
  }
`;

const SignUpInitialValues = {
  email: "",
  password: "",
  name: "",
  organization: "",
  address: "",
  tel: "",
};
const SignUpSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(4, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
  name: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
  organization: Yup.string().max(50, "Too Long!"),
  address: Yup.string().max(50, "Too Long!"),
  tel: Yup.string().max(20, "Too Long!"),
});

export const SignUpForm = () => {
  return (
    <SignUpFormWrapper>
      <Formik
        initialValues={SignUpInitialValues}
        validationSchema={SignUpSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          const params = new URL(document.location).searchParams;
          if (params.has("code")) {
            values.code = params.get("code");
          }
          axios
            .post("/api/signUp", values)
            .then(function (response) {
              if (response.data) {
                if (response.data.state === "send_email") {
                  window.location.href = "/signUpFinish";
                } else {
                  window.location.href = "/programs";
                }
              }
            })
            .catch(function (error) {
              if (error.response) {
                const { data } = error.response;
                if (data.message === "already exists email") {
                  setErrors({
                    email: "すでにこのE-mailは使われています。",
                  });
                }
              }
            })
            .finally(function () {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <EmailField
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <PasswordField
              marginTop={0.8}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <NameField
              marginTop={0.8}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <OrganizationField
              marginTop={0.8}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <AddressField
              marginTop={0.8}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TelField
              marginTop={0.8}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TopMargin marginTop={2.0}>
              <Centering>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                >
                  利用規約に同意して作成
                </Button>
              </Centering>
            </TopMargin>
          </form>
        )}
      </Formik>
    </SignUpFormWrapper>
  );
};

const updateUserInitialValues = {
  name: "",
  initials: "",
  organization: "",
  address: "",
  tel: "",
};
const updateUserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
  initials: Yup.string()
    .min(2, "Too Short!")
    .max(4, "Too Long!")
    .required("Required"),
  organization: Yup.string().max(50, "Too Long!"),
  address: Yup.string().max(50, "Too Long!"),
  tel: Yup.string().max(20, "Too Long!"),
});

const UpdateUserFormWrapper = styled.div`
  width: 92%;
  @media (min-width: 768px) {
    width: 28rem;
    margin: 0 auto;
    padding: 2rem 0;
  }
`;

const UpdateUserFormNames = styled.div`
  display: flex;
`;

export class UpdateUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { initialValues: updateUserInitialValues, open: false };
  }

  componentDidMount() {
    async function load() {
      const response = await axios.get("/api/me");
      if (response.data) {
        const { user } = response.data;
        return user;
      } else {
        throw new Error();
      }
    }
    load()
      .then((user) => {
        this.setState({ ...this.state, initialValues: user });
      })
      .catch(console.error);
  }

  handleClickOpen = (e) => {
    e.preventDefault();
    this.setState({ ...this.state, open: true });
  };

  handleClose = () => {
    this.setState({ ...this.state, open: false });
  };

  handleDelete = () => {
    axios.delete("/api/deleteUser").then(function () {
      window.location.href = "/";
    });
  };

  render() {
    const { initialValues, open } = this.state;
    return (
      <UpdateUserFormWrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={updateUserSchema}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            axios
              .put("/api/updateUser", values)
              .then(function () {
                window.location.href = "/programs";
              })
              .finally(function () {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <UpdateUserFormNames>
                <NameField
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  flex={`1 1 auto`}
                />
                <InitialsField
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  flex={`0 1 120px`}
                  marginLeft={1.0}
                />
              </UpdateUserFormNames>
              <OrganizationField
                marginTop={0.8}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <AddressField
                marginTop={0.8}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <TelField
                marginTop={0.8}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <TopMargin marginTop={2.0}>
                <Rightify>
                  <TextLink
                    href="#"
                    color="secondary"
                    handleClick={this.handleClickOpen}
                  >
                    アカウントを削除する
                  </TextLink>
                </Rightify>
              </TopMargin>
              <TopMargin marginTop={2.0}>
                <Centering>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    アカウント変更
                  </Button>
                </Centering>
              </TopMargin>
            </form>
          )}
        </Formik>
        <Dialog open={open} onClose={this.handleClose}>
          <DialogTitle>本当にアカウントを削除しますか？</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              キャンセル
            </Button>
            <Button onClick={this.handleDelete} color="secondary" autoFocus>
              削除する
            </Button>
          </DialogActions>
        </Dialog>
      </UpdateUserFormWrapper>
    );
  }
}

const updateEmailInitialValues = {
  email: "",
  password: "",
};
const updateEmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(4, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
});

const UpdateEmailFormWrapper = styled.div`
  width: 92%;
  @media (min-width: 768px) {
    width: 28rem;
    margin: 0 auto;
    padding: 2rem 0;
  }
`;

export class UpdateEmailForm extends React.Component {
  render() {
    return (
      <UpdateEmailFormWrapper>
        <Formik
          initialValues={updateEmailInitialValues}
          validationSchema={updateEmailSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            axios
              .put("/api/updateEmail", values)
              .then(function () {
                window.location.href = "/programs";
              })
              .catch(function (error) {
                if (error.response) {
                  setErrors({
                    email: "すでにこのE-mailは使われています。",
                  });
                }
              })
              .finally(function () {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <EmailField
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <PasswordField
                marginTop={1.2}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <TopMargin marginTop={2.0}>
                <Centering>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    メールアドレス変更
                  </Button>
                </Centering>
              </TopMargin>
            </form>
          )}
        </Formik>
      </UpdateEmailFormWrapper>
    );
  }
}

const updatePasswordInitialValues = {
  oldPassword: "",
  newPassword: "",
};
const updatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(4, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
  newPassword: Yup.string()
    .min(4, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
});

const UpdatePasswordFormWrapper = styled.div`
  width: 92%;
  @media (min-width: 768px) {
    width: 28rem;
    margin: 0 auto;
    padding: 2rem 0;
  }
`;

export class UpdatePasswordForm extends React.Component {
  render() {
    return (
      <UpdatePasswordFormWrapper>
        <Formik
          initialValues={updatePasswordInitialValues}
          validationSchema={updatePasswordSchema}
          onSubmit={(values, { setSubmitting }) => {
            axios
              .put("/api/updatePassword", values)
              .then(function () {
                window.location.href = "/programs";
              })
              .finally(function () {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <OldPasswordField
                marginTop={1.2}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <NewPasswordField
                marginTop={1.2}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <TopMargin marginTop={2.0}>
                <Centering>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    パスワード変更
                  </Button>
                </Centering>
              </TopMargin>
            </form>
          )}
        </Formik>
      </UpdatePasswordFormWrapper>
    );
  }
}

const resetPasswordStep1InitialValues = {
  email: "",
};
const resetPasswordStep1Schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ResetPasswordStep1FormWrapper = styled.div`
  width: 92%;
  @media (min-width: 768px) {
    width: 28rem;
    margin: 0 auto;
    padding: 2rem 0;
  }
`;

export class ResetPasswordStep1Form extends React.Component {
  render() {
    return (
      <ResetPasswordStep1FormWrapper>
        <Formik
          initialValues={resetPasswordStep1InitialValues}
          validationSchema={resetPasswordStep1Schema}
          onSubmit={(values) => {
            axios.post("/api/resetPasswordStep1", values).finally(function () {
              window.location.href = "/resetPasswordStep1Finish";
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <EmailField
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <TopMargin marginTop={2.0}>
                <Centering>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    パスワード再発行
                  </Button>
                </Centering>
              </TopMargin>
            </form>
          )}
        </Formik>
      </ResetPasswordStep1FormWrapper>
    );
  }
}

const resetPasswordStep2InitialValues = {
  newPassword: "",
};
const resetPasswordStep2Schema = Yup.object().shape({
  newPassword: Yup.string()
    .min(4, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
});

const ResetPasswordStep2FormWrapper = styled(ResetPasswordStep1FormWrapper)``;

export class ResetPasswordStep2Form extends React.Component {
  render() {
    return (
      <ResetPasswordStep2FormWrapper>
        <Formik
          initialValues={resetPasswordStep2InitialValues}
          validationSchema={resetPasswordStep2Schema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            if (urlParams.get("code")) {
              values.code = urlParams.get("code");
            }
            axios
              .post("/api/resetPasswordStep2", values)
              .then(function () {
                window.location.href = "/programs";
              })
              .finally(function () {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <NewPasswordField
                marginTop={1.2}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <TopMargin marginTop={2.0}>
                <Centering>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    パスワード再発行
                  </Button>
                </Centering>
              </TopMargin>
            </form>
          )}
        </Formik>
      </ResetPasswordStep2FormWrapper>
    );
  }
}

const CreateProgramFormWrapper = styled.div`
  width: 92%;
  @media (min-width: 768px) {
    width: 28rem;
    margin: 0 auto;
    padding: 2rem 0;
  }
`;

const createProgramSchema = Yup.object().shape({
  title: Yup.string()
    .min(4, "Too Short!")
    .max(200, "Too Long!")
    .required("Required"),
  emails: Yup.string().test("emails", "Invalid email", (value) => {
    if (value) {
      return value
        .split(",")
        .map((v) => validator.isEmail(v))
        .every((b) => b === true);
    } else {
      return true;
    }
  }),
  organization: Yup.string().max(50, "Too Long!").required("Required"),
  address: Yup.string().max(50, "Too Long!").required("Required"),
  tel: Yup.string().max(20, "Too Long!").required("Required"),
});

export const CreateProgramForm = ({ user }) => {
  const initialValues = {
    title: "",
    emails: "",
    organization: user.organization,
    address: user.address,
    tel: user.tel,
  };
  return (
    <CreateProgramFormWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={createProgramSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          const { title, emails, ...userAttr } = values;
          axios
            .post("/api/programs", {
              title,
              emails: emails ? emails.split(",") : [],
              userAttr,
            })
            .then(function (response) {
              window.location.href = "/programs";
            })
            .catch(function (error) {
              if (error.response) {
              }
            })
            .finally(function () {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Paragraph>
              まずはプログラムを作成しましょう。
              <br />
              SALITへのメッセージやデータのやり取りが可能になります。
            </Paragraph>
            <TitleField
              marginTop={0.8}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <Paragraph>
              あなたのスタッフも招待して共有しましょう。
              <br />
              （共有したいスタッフは後からでも追加できます）
            </Paragraph>
            <EmailsField
              marginTop={0.8}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TopMargin marginTop={2} />
            <Paragraph>
              ※ 新規プログラムを作成するには
              <br />
              会社名・住所・電話番号の登録が必要です。
            </Paragraph>
            <OrganizationField
              marginTop={0.8}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <AddressField
              marginTop={0.8}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TelField
              marginTop={0.8}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <TopMargin marginTop={2.0}>
              <Centering>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                >
                  プログラムを作成
                </Button>
              </Centering>
            </TopMargin>
          </form>
        )}
      </Formik>
    </CreateProgramFormWrapper>
  );
};

const createMessageSchema = Yup.object().shape({
  text: Yup.string().required("Required"),
});

const MessageForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.4rem 0.2rem;
`;

const TextareaAutosizeWrapper = styled(TextareaAutosize)`
  flex: 1 0 auto;
  margin-right: 0.2rem;
`;

const SendIconWrapper = styled.div`
  color: #00b3cd;
`;

const AttachFileIconWrapper = styled.div`
  position: absolute;
  color: #00b3cd;
  right: 3rem;
  top: 0.75rem;
`;

const MessageInputWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: #fff;
  flex: 0 0 3rem;
`;

export function MessageInput(props) {
  const [program, setProgram] = useContext(ProgramContext);
  const { getInputProps, handleFileDialog } = props;
  const initialValues = {
    text: "",
  };

  const myRef = React.useRef(null);
  React.useEffect(() => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  }, [program.messages]);

  return (
    <MessageInputWrapper ref={myRef}>
      <Formik
        initialValues={initialValues}
        validationSchema={createMessageSchema}
        onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
          const { text } = values;
          axios
            .post(`/api/programs/${program.uuid}/messages`, {
              text,
            })
            .then(function (response) {
              if (response.data) {
                setProgram(response.data);
                resetForm();
              }
            })
            .catch(function (error) {
              if (error.response) {
              }
            })
            .finally(function () {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <MessageForm onSubmit={handleSubmit} noValidate autoComplete="off">
            <AttachFileIconWrapper>
              <input {...getInputProps()} />
              <AttachFileIcon onClick={handleFileDialog} />
            </AttachFileIconWrapper>
            <TextareaAutosizeWrapper
              name="text"
              value={values.text}
              onChange={handleChange}
              onBlur={handleBlur}
              minRows={5}
            />
            <SendIconWrapper>
              <IconButton
                type="submit"
                size="small"
                color="inherit"
                component="button"
                disabled={
                  isEmpty(values.text) || !isEmpty(errors.text) || isSubmitting
                }
              >
                <SendIcon />
              </IconButton>
            </SendIconWrapper>
          </MessageForm>
        )}
      </Formik>
    </MessageInputWrapper>
  );
}

const searchMemberSchema = Yup.object().shape({
  text: Yup.string().max(255, "Too Long!"),
});

const SearchMemberFormWrapper = styled.div`
  background-color: #fff;
`;

export const SearchMemberForm = (props) => {
  const { program, onSearch } = props;
  const initialValues = {
    text: "",
  };
  return (
    <SearchMemberFormWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={searchMemberSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { text } = values;
          axios
            .post(`/api/programs/${program.uuid}/searchMembers`, {
              text,
            })
            .then(function (response) {
              if (response.data) {
                onSearch(response.data);
              }
            })
            .catch(function (error) {
              if (error.response) {
              }
            })
            .finally(function () {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <SearchMemberField
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </form>
        )}
      </Formik>
    </SearchMemberFormWrapper>
  );
};

const inviteMembersSchema = Yup.object().shape({
  emails: Yup.string().test("emails", "Invalid email", (value) => {
    if (value) {
      return value
        .split(",")
        .map((v) => validator.isEmail(v))
        .every((b) => b === true);
    } else {
      return true;
    }
  }),
  isPartner: Yup.boolean(),
});

export const InviteMembersForm = (props) => {
  const { program, open, handleClose } = props;
  const initialValues = {
    emails: "",
    isPartner: false,
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={inviteMembersSchema}
      onSubmit={(values, { setSubmitting }) => {
        const { emails, isPartner } = values;
        axios
          .post(`/api/programs/${program.uuid}/inviteMembers`, {
            emails: emails ? emails.split(",") : [],
            isPartner,
          })
          .then(function (response) {
            if (response.data) {
              handleClose(response.data);
            }
          })
          .catch(function (error) {
            if (error.response) {
            }
          })
          .finally(function () {
            setSubmitting(false);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <DialogTitle id="form-dialog-title">プログラムに招待</DialogTitle>
            <DialogContent>
              <DialogContentText>
                カンマで区切ることで複数の招待を同時に行えます。
              </DialogContentText>
              <EmailsField
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting}>
                招待
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

const filterProgramSchema = Yup.object().shape({
  q: Yup.string().max(20, "Too Long!"),
  who: Yup.string().max(20, "Too Long!"),
  status: Yup.string(),
  mine: Yup.bool(),
  archived: Yup.bool(),
});

const FilterProgramFormTextField = styled.div`
  text-align: right;
`;

const FilterProgramFormWrapper = styled.div`
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: #707070;
`;

export const FilterProgramForm = (props) => {
  const { user, onSearch } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialValues = {
    q: searchParams.get("q") || "",
    who: searchParams.get("who") || "",
    status: searchParams.get("status") || "",
    mine: searchParams.get("mine") === "true" || false,
    archived: searchParams.get("archived") === "true" || false,
  };

  const handleReset = () => {
    onSearch("", false, false);
  };

  return (
    <FilterProgramFormWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={filterProgramSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { q, who, status, mine, archived } = values;
          onSearch(q, who, status, mine, archived);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FilterProgramFormTextField>
              <TextLink color="inherit" onClick={handleReset}>
                リセット
              </TextLink>
            </FilterProgramFormTextField>
            <TopMargin marginTop={1.0}>
              <SearchWordField
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </TopMargin>
            <TopMargin marginTop={1.0}>
              <SearchUserField
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </TopMargin>
            {user.type === "operator" && (
              <TopMargin marginTop={1.0}>
                <FormControl fullWidth={true}>
                  <InputLabel id="status-select-label">ステータス</InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    name="status"
                    autoWidth={true}
                    value={values.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="" />
                    {Object.keys(programStatusMap).map((k) => (
                      <MenuItem key={k} value={k}>
                        {programStatusMap[k]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TopMargin>
            )}
            <TopMargin marginTop={1.0}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.mine}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="mine"
                      color="primary"
                    />
                  }
                  label="自身が担当者"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.archived}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="archived"
                      color="primary"
                    />
                  }
                  label="アーカイブも含む"
                />
              </FormGroup>
            </TopMargin>
            <TopMargin marginTop={2.0}>
              <Centering>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  フィルター
                </Button>
              </Centering>
            </TopMargin>
          </form>
        )}
      </Formik>
    </FilterProgramFormWrapper>
  );
};

const MetaFormChipListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const fieldMap = {
  N0001: {
    label: "納期",
    fieldType: "date",
    initial: "",
    choices: [],
  },
  N0002: {
    label: "使用用途",
    fieldType: "select",
    initial: "",
    choices: [
      "TVプログラム",
      "TVCM",
      "インターネット配信",
      "WEBCM",
      "MV",
      "イベント",
      "DVD/Blu-rayパッケージ",
      "企業VP",
      "その他",
    ],
  },
  N0003: {
    label: "納品方法",
    fieldType: "radio",
    initial: "",
    choices: ["お引き取り", "宅配便", "バイク便", "その他"],
    hasOthers: true,
  },
  N0004: {
    label: "EED作業内容",
    fieldType: "checkbox",
    initial: [],
    choices: [
      "テロップ入れ",
      "肌修正",
      "カラコレ",
      "バレ消し",
      "各種フォーマット",
      "その他",
    ],
    hasOthers: true,
  },
  N0005: {
    label: "素材サイズ",
    fieldType: "radio",
    initial: "",
    choices: ["1920 × 1080", "3840 × 2160"],
    hasOthers: false,
  },
  N0006: {
    label: "納品サイズ",
    fieldType: "radio",
    initial: "",
    choices: ["1920 × 1080", "3840 × 2160"],
    hasOthers: false,
  },
  N0007: {
    label: "納品物",
    fieldType: "checkbox",
    initial: "",
    choices: ["HDCAM", "XDCAM", "データ", "DVD", "Blu-ray"],
  },
  N0008: {
    label: "尺",
    fieldType: "text",
    initial: "",
    choices: [],
  },
  N0009: {
    label: "タイムコード",
    fieldType: "radio",
    initial: "",
    choices: ["DF", "NDF"],
    hasOthers: false,
  },
  N0010: {
    label: "素材フレームレート",
    fieldType: "radio",
    initial: "",
    choices: ["23.98fps", "24fps", "29.97fps"],
    hasOthers: false,
  },
  N0011: {
    label: "納品フレームレート",
    fieldType: "radio",
    initial: "",
    choices: ["23.98fps", "24fps", "29.97fps"],
    hasOthers: false,
  },
  N0012: {
    label: "ハーディングチェック",
    fieldType: "radio",
    initial: "",
    choices: ["必要", "不要"],
    hasOthers: false,
  },
  N0013: {
    label: "納品数",
    fieldType: "text",
    initial: "",
    choices: [],
  },
  N0014: {
    label: "配送先",
    fieldType: "text",
    initial: "",
    choices: [],
  },
  N0015: {
    label: "ナレーション収録",
    fieldType: "radio",
    initial: "",
    choices: ["有", "無"],
    hasOthers: false,
  },
  N0016: {
    label: "音効(BGM・SE)",
    fieldType: "radio",
    initial: "",
    choices: ["有", "無"],
    hasOthers: false,
  },
  N0017: {
    label: "納品ファイルコーデック(音声)",
    fieldType: "checkbox",
    initial: [],
    choices: [
      "AIFF",
      "WAV",
      "Pro tools セッションデータ",
      "MP3",
      "パラデータ",
      "その他",
    ],
    hasOthers: true,
  },
  N0018: {
    label: "音声レベル",
    fieldType: "checkbox",
    initial: [],
    choices: ["ラウドネス", "-20dB", "Full bit", "その他"],
    hasOthers: true,
  },
  N0019: {
    label: "素材広告主",
    fieldType: "text",
    initial: "",
    choices: [],
    fullWidth: true,
    helperText: "20文字以内",
  },
  N0020: {
    label: "CM素材名",
    fieldType: "text",
    initial: "",
    choices: [],
    fullWidth: true,
    helperText: "30文字以内",
  },
  N0021: {
    label: "10桁コード",
    fieldType: "text",
    initial: "",
    choices: [],
    helperText: "(〇〇〇〇＝〇〇〇〇〇〇)の形式",
  },
  N0022: {
    label: "商品名",
    fieldType: "text",
    initial: "",
    choices: [],
    fullWidth: true,
    helperText: "20文字以内",
  },
  N0023: {
    label: "制作広告会社",
    fieldType: "text",
    initial: "",
    choices: [],
    fullWidth: true,
    helperText: "20文字以内",
  },
  N0024: {
    label: "制作会社",
    fieldType: "text",
    initial: "",
    choices: [],
    fullWidth: true,
    helperText: "20文字以内",
  },
  N0025: {
    label: "備考",
    fieldType: "text",
    initial: "",
    choices: [],
    fullWidth: true,
  },
  N0026: {
    label: "録画日",
    fieldType: "date",
    initial: "",
    choices: [],
  },
  N0027: {
    label: "クレジット用タイトル",
    fieldType: "text",
    initial: "",
    choices: [],
    fullWidth: true,
  },
  N0028: {
    label: "アーティスト",
    fieldType: "text",
    initial: "",
    choices: [],
  },
  N0029: {
    label: "ディレクター",
    fieldType: "text",
    initial: "",
    choices: [],
  },
  N0030: {
    label: "クライアント",
    fieldType: "text",
    initial: "",
    choices: [],
  },
  N0031: {
    label: "プロダクション",
    fieldType: "text",
    initial: "",
    choices: [],
  },
};

function MetaFormChipList(props) {
  const { values, onToggle } = props;

  const handleChip = (name, v) => {
    onToggle(name, v);
  };

  const chips = [];
  for (const [key, value] of Object.entries(fieldMap)) {
    chips.push({
      name: key,
      label: value.label,
    });
  }

  return (
    <MetaFormChipListWrapper>
      {chips.map((c) => (
        <MetaFormChip
          key={c.name}
          name={c.name}
          label={c.label}
          onClick={handleChip}
          values={values}
        />
      ))}
    </MetaFormChipListWrapper>
  );
}

const MetaFormChipWrapper = styled.div`
  margin: 0.25rem;
`;

function MetaFormChip(prop) {
  const { name, onClick, values, ...others } = prop;

  const idx = findIndex(values.fields, { template: name });
  let used;
  if (idx > -1) {
    used = true;
  } else {
    used = false;
  }

  const handleClick = () => {
    onClick(name, !used);
  };

  return (
    <MetaFormChipWrapper>
      <Chip
        clickable
        onClick={handleClick}
        onDelete={used ? handleClick : null}
        deleteIcon={used ? <DoneIcon /> : null}
        {...others}
      />
    </MetaFormChipWrapper>
  );
}

const MetaFormForm = styled.form`
  margin-top: 1rem;
`;

const FormControlWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  width: 100%;
`;

const MetaFormChipControl = styled.div`
  display: flex;
  color: #00b3cd;
`;

function MetaFormField(props) {
  const {
    user,
    formField,
    idx,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
  } = props;
  let inner;
  const fieldType = fieldMap[formField.template].fieldType;
  let alignItems = "center";
  switch (fieldType) {
    case "text":
      inner = (
        <TextFField
          {...{
            user,
            formField,
            idx,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }}
        />
      );
      break;
    case "date":
      inner = (
        <TextFField
          {...{
            user,
            formField,
            idx,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            type: "date",
          }}
        />
      );
      break;
    case "select":
      inner = (
        <SelectFField
          {...{
            user,
            formField,
            idx,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }}
        />
      );
      break;
    case "radio":
      inner = (
        <RadioFField
          {...{
            user,
            formField,
            idx,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }}
        />
      );
      alignItems = "flex-end";
      break;
    case "checkbox":
      inner = (
        <CheckBoxFField
          {...{
            user,
            formField,
            idx,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }}
        />
      );
      alignItems = "flex-end";
      break;
    default:
      return null;
  }
  return (
    <FormControlWrapper alignItems={alignItems}>{inner}</FormControlWrapper>
  );
}

const MetaFormButtonList = styled.div`
  display: flex;
  justify-content: space-around;
  width: 20rem;
  margin: 2rem auto 0;
  color: #fff;
`;

const MetaFormDeleteLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5rem;
`;

const RequestButton = styled(Button)`
  color: #fff !important;
`;

const RequiredMessage = styled.div`
  margin-left: 0.5rem;
  font-size: 0.8;
  color: #eb6d9a;
`;

const schema = Yup.object().shape({
  title: Yup.string()
    .min(4, "Too Short!")
    .max(200, "Too Long!")
    .required("Required"),
  fields: Yup.array().of(
    Yup.object().shape({
      template: Yup.string().required("Required"), // these constraints take precedence
    })
  ),
});

export function MetaForm(props) {
  const { user, program, initialFormFields } = props;

  const [formFields, setFormFields] = useState(initialFormFields);
  const [{ open, dialogOpen }, setState] = useState({
    open: false,
    dialogOpen: false,
  });

  const fields = formFields.map((f) => {
    try {
      f.content = JSON.parse(f.content);
    } catch {}
    return f;
  });

  const initialValues = { fields, title: program.title };

  const handleToggle = (name, v, values, handlers) => {
    const { arrayHelpers } = handlers;
    if (v) {
      const temp = fieldMap[name];
      const data = {
        label: temp["label"],
        fieldType: temp["fieldType"],
        template: name,
        content: temp["initial"],
        required: true,
      };
      if (temp.hasOthers) {
        data.others = "";
      }
      arrayHelpers.push(data);
    } else {
      const idx = findIndex(values.fields, { template: name });
      arrayHelpers.remove(idx);
    }
  };

  const createHandleRequest = (values) => () => {
    axios
      .post(`/api/programs/${program.uuid}/form/request`, values)
      .then(function (response) {
        if (response.data) {
          setFormFields(response.data.formFields);
        }
      })
      .finally(function () {});
  };

  const handleDialog = () => {
    setState({ open, dialogOpen: true });
  };

  const handleClose = () => {
    setState({ open, dialogOpen: false });
  };

  const handleDelete = () => {
    axios
      .delete(`/api/programs/${program.uuid}`)
      .then(function () {
        window.location.href = "/programs";
      })
      .finally(function () {});
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        values.fields = values.fields.map((f) => {
          const req = pick(f, ["id", "template", "content", "required"]);
          if (f.others) {
            req.others = f.others;
          }
          return req;
        });

        let uri;
        if (user.type === "operator") {
          uri = `/api/programs/${program.uuid}/form/update`;
        } else {
          uri = `/api/programs/${program.uuid}/form/input`;
        }
        axios
          .put(uri, values)
          .then(function (response) {
            if (response.data) {
              setFormFields(response.data.formFields);
            }
          })
          .finally(function () {
            setSubmitting(false);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <>
          <MetaFormChipControl
            onClick={() => {
              setState({ open: !open, dialogOpen: false });
            }}
          >
            {user.type === "operator" && (
              <>
                <TextLink color="inherit" underline="none" component="button">
                  フォームを追加
                </TextLink>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </>
            )}
          </MetaFormChipControl>
          <FieldArray
            name="fields"
            render={(arrayHelpers) => (
              <>
                {open && (
                  <MetaFormChipList
                    values={values}
                    onToggle={(name, v) => {
                      handleToggle(name, v, values, {
                        arrayHelpers,
                      });
                    }}
                  />
                )}
                <MetaFormForm onSubmit={handleSubmit} autoComplete="off">
                  <FormControlWrapper>
                    <TitleField
                      flex="1 0 auto"
                      required={true}
                      {...{
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                      }}
                    />
                  </FormControlWrapper>
                  {values.fields.map((formField, idx) => (
                    <MetaFormField
                      key={idx}
                      {...{
                        user,
                        formField,
                        idx,
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                      }}
                    />
                  ))}
                  <MetaFormButtonList>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      endIcon={<SaveIcon />}
                    >
                      SAVE
                    </Button>
                    {user.type === "operator" && (
                      <RequestButton
                        variant="contained"
                        color="secondary"
                        onClick={createHandleRequest(values)}
                        endIcon={<SendIcon />}
                      >
                        入力依頼
                      </RequestButton>
                    )}
                  </MetaFormButtonList>
                </MetaFormForm>
                <MetaFormDeleteLinkWrapper>
                  <TextLink
                    color="secondary"
                    underline="none"
                    component="button"
                    onClick={handleDialog}
                  >
                    プログラムを削除
                  </TextLink>
                </MetaFormDeleteLinkWrapper>
                <Dialog open={dialogOpen} onClose={handleClose}>
                  <DialogTitle>本当に削除しますか？</DialogTitle>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      キャンセル
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                      削除する
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          />
        </>
      )}
    </Formik>
  );
}

function TextFField(props) {
  const {
    user,
    formField,
    idx,
    errors,
    touched,
    handleChange,
    handleBlur,
    type = "text",
  } = props;
  const InputProps = { onChange: handleChange, onBlur: handleBlur };
  const { template, content, required } = formField;
  const label = fieldMap[template].label;
  const fullWidth = fieldMap[template].fullWidth || false;
  const helperText = fieldMap[template].helperText;
  const keyV = `fields.${idx}.content`;
  const keyR = `fields.${idx}.required`;

  const err = get(errors, keyV, undefined);
  const touch = get(touched, keyV, undefined);

  return (
    <>
      <TextField
        type={type}
        variant="outlined"
        name={keyV}
        value={content}
        error={touch && err}
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={InputProps}
        fullWidth={fullWidth}
        helperText={(touch && err) || helperText}
      />
      {user.type === "operator" && (
        <Switch
          name={keyR}
          checked={required}
          onChange={handleChange}
          color="primary"
        />
      )}
      {user.type === "customer" && required && (
        <RequiredMessage>※ 必須</RequiredMessage>
      )}
    </>
  );
}

const SelectFFieldFormControlWithStyle = styled(FormControl)`
  min-width: 12rem !important;
`;

function SelectFField(props) {
  const { user, formField, idx, errors, touched, handleChange, handleBlur } =
    props;
  const InputProps = { onChange: handleChange, onBlur: handleBlur };
  const { template, content, required } = formField;
  const label = fieldMap[template].label;
  const keyV = `fields.${idx}.content`;
  const keyR = `fields.${idx}.required`;
  const err = get(errors, keyV, undefined);
  const touch = get(touched, keyV, undefined);
  return (
    <>
      <SelectFFieldFormControlWithStyle error={touch && err}>
        <InputLabel shrink id={`${template}-select-label`}>
          {label}
        </InputLabel>
        <Select
          labelId={`${template}-select-label`}
          id={`${template}-select`}
          name={keyV}
          value={content}
          {...InputProps}
        >
          {fieldMap[template].choices.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </SelectFFieldFormControlWithStyle>
      {user.type === "operator" && (
        <Switch
          name={keyR}
          checked={required}
          onChange={handleChange}
          color="primary"
        />
      )}
      {user.type === "customer" && required && (
        <RequiredMessage>※ 必須</RequiredMessage>
      )}
    </>
  );
}

function RadioFField(props) {
  const { user, formField, idx, errors, touched, handleChange, handleBlur } =
    props;
  const InputProps = { onChange: handleChange, onBlur: handleBlur };
  const { template, content, others, required } = formField;
  const label = fieldMap[template].label;
  const keyV = `fields.${idx}.content`;
  const keyV2 = `fields.${idx}.others`;
  const keyR = `fields.${idx}.required`;
  const err = get(errors, keyV, undefined);
  const touch = get(touched, keyV, undefined);
  return (
    <>
      <FormControl component="fieldset" error={touch && err}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup name={keyV} value={content} {...InputProps}>
          {fieldMap[template].choices.map((v) => (
            <FormControlLabel
              key={v}
              value={v}
              control={<Radio color="primary" />}
              label={v}
            />
          ))}
        </RadioGroup>
        {touch && err && <FormHelperText>{err}</FormHelperText>}
      </FormControl>
      {content === "その他" && (
        <TextField
          type="text"
          variant="outlined"
          name={keyV2}
          value={others}
          error={touch && err}
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={InputProps}
          helperText={touch && err}
        />
      )}
      {user.type === "operator" && (
        <Switch
          name={keyR}
          checked={required}
          onChange={handleChange}
          color="primary"
        />
      )}
      {user.type === "customer" && required && (
        <RequiredMessage>※ 必須</RequiredMessage>
      )}
    </>
  );
}

function CheckBoxFField(props) {
  const {
    user,
    formField,
    idx,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
  } = props;
  const InputProps = { onChange: handleChange, onBlur: handleBlur };
  const { template, content, others, required } = formField;
  const label = fieldMap[template].label;
  const keyV = `fields.${idx}.content`;
  const keyV2 = `fields.${idx}.others`;
  const keyR = `fields.${idx}.required`;
  const err = get(errors, keyV, undefined);
  const touch = get(touched, keyV, undefined);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setFieldValue(keyV, [...content, e.target.name]);
    } else {
      const newValues = content.filter((v) => v !== e.target.name);
      setFieldValue(keyV, newValues);
    }
  };

  return (
    <>
      <FormControl component="fieldset" error={touch && err}>
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup>
          {fieldMap[template].choices.map((v) => (
            <FormControlLabel
              key={v}
              value={v}
              control={
                <Checkbox
                  checked={content.includes(v)}
                  onChange={handleCheck}
                  name={v}
                  color="primary"
                />
              }
              label={v}
            />
          ))}
        </FormGroup>
        {touch && err && <FormHelperText>{err}</FormHelperText>}
      </FormControl>
      {content.includes("その他") && (
        <TextField
          type="text"
          variant="outlined"
          name={keyV2}
          value={others}
          error={touch && err}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={InputProps}
          helperText={touch && err}
        />
      )}
      {user.type === "operator" && (
        <Switch
          name={keyR}
          checked={required}
          onChange={handleChange}
          color="primary"
        />
      )}
      {user.type === "customer" && required && (
        <RequiredMessage>※ 必須</RequiredMessage>
      )}
    </>
  );
}
