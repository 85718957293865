import React from "react";
import Link from "@mui/material/Link";
import StarIcon from "@mui/icons-material/Star";
import Tooltip from "@mui/material/Tooltip";
import styled from "@emotion/styled";

import MonkeyImg from "./monkey.png";
import { organizationName, addressTel } from "./module";

export const FlexGrow = styled.div`
  flex-grow: 1;
`;

export const PageTitle = styled.h1`
  font-size: 1.2rem;
  color: #707070;
`;

export const Centering = styled.div`
  display: flex;
  justify-content: center;
`;

export const Rightify = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TopMargin = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}rem` : 0)};
`;

const LinkWithStyle = styled(Link)`
  cursor: pointer;
`;

export function TextLink(props) {
  const { children, handleClick, underline = "always", ...others } = props;
  return (
    <LinkWithStyle
      variant="body2"
      underline={underline}
      onClick={handleClick}
      {...others}
    >
      {children}
    </LinkWithStyle>
  );
}

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5rem 0 2rem;
  color: #707070;
  width: 92%;
  margin: 0 auto;
  @media (min-width: 768px) {
    width: 400px;
    margin: 0 auto;
  }
`;
export function Footer() {
  return (
    <FooterWrapper>
      <TextLink href="/" color="inherit">
        TOP
      </TextLink>
      <TextLink href="https://salmix.co.jp/" target="_blank" color="inherit">
        会社概要
      </TextLink>
      <TextLink href="/agreement" color="inherit">
        Agreement
      </TextLink>
      <TextLink
        href="https://salmix.co.jp/policy/"
        target="_blank"
        color="inherit"
      >
        Privacy Policy
      </TextLink>
    </FooterWrapper>
  );
}

export const VerticalCenterBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  min-height: 600px;
  padding: 2rem 0;
  @media (min-width: 768px) {
    width: 720px;
    margin: 0 auto;
  }
`;

export const LogoImg = styled.img`
  width: 10rem;
  margin-top: 1.2rem;
`;

export const Paragraph = styled.p`
  font-size: 0.8rem;
  color: #707070;
  overflow-wrap: break-word;
`;

const CircleIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
`;

const COLOR_MAP = {
  customer: "#6EBA44",
  partner: "#E1A245",
  operator: "#EB6D9A",
};

function getColor(props) {
  let v;
  if (props.active) {
    v = "#fff";
  } else {
    v = COLOR_MAP[props.type];
  }
  return v;
}

function getBorderColor(props) {
  return COLOR_MAP[props.type];
}

function getBgColor(props) {
  let v;
  if (props.active) {
    v = COLOR_MAP[props.type];
  } else {
    v = "#fff";
  }
  return v;
}

export const InitialsIcon = styled(CircleIcon)`
  border: solid 1.5px;
  color: ${(props) => getColor(props)};
  border-color: ${(props) => getBorderColor(props)};
  background-color: ${(props) => getBgColor(props)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
`;

const ImageIcon = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
`;

const DeleteTextLink = styled(TextLink)`
  font-size: 0.75rem !important;
`;

const UserIconWrapper = styled.div`
  position: relative;
`;

const RequesterIconWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: -8px;
  color: #707070;
`;

export function UserIcon(props) {
  const {
    user,
    member,
    program,
    active,
    operatorAsMonkey,
    showStarMark,
    handleClick,
  } = props;

  const onClick = () => {
    if (handleClick) {
      handleClick(member);
    }
  };

  const onDelete = () => {
    const { handleDelete } = props;
    if (handleDelete) {
      handleDelete(member);
    }
  };

  let title;
  if (user.type === "operator") {
    if (member.type === "customer" || member.type === "partner") {
      title = (
        <>
          {`${organizationName(member)} ${member.email} ${addressTel(member)}`}
          <br />
          <DeleteTextLink color="secondary" handleClick={onDelete}>
            メンバーを削除
          </DeleteTextLink>
        </>
      );
    } else {
      title = <>{`${organizationName(member)} ${member.email}`}</>;
    }
  } else {
    if (member.type === "customer") {
      if (program && program.requesterUuid === user.uuid) {
        title = (
          <>
            {`${organizationName(member)} ${member.email}`}
            <br />
            <DeleteTextLink color="secondary" handleClick={onDelete}>
              メンバーを削除
            </DeleteTextLink>
          </>
        );
      } else {
        title = <>{`${organizationName(member)} ${member.email}`}</>;
      }
    } else {
      title = <>{`${organizationName(member)} ${member.email}`}</>;
    }
  }

  const inner = (
    <UserIconWrapper>
      {member.type === "operator" && operatorAsMonkey ? (
        <CircleIcon>
          <ImageIcon src={MonkeyImg} />
        </CircleIcon>
      ) : (
        <InitialsIcon active={active} type={member.type} onClick={onClick}>
          {member.initials}
        </InitialsIcon>
      )}
      {showStarMark && (
        <RequesterIconWrapper>
          <StarIcon />
        </RequesterIconWrapper>
      )}
    </UserIconWrapper>
  );

  return (
    <Tooltip title={title} placement="top" arrow interactive>
      {inner}
    </Tooltip>
  );
}
