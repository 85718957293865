import * as React from "react";

function SvgDelivery(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20.44" {...props}>
      <path
        d="M2.753 12.2h4.679V5.07h7.112v7.13h4.68l-8.236 8.24zM17.5 8.144V7h3.349V1.148H1.147V7h3.326v1.144H0V0h22v8.144z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDelivery;
