import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  RouterProvider,
  Outlet,
  createBrowserRouter,
  useOutletContext,
  useLocation,
  useParams,
  useNavigate,
  useLoaderData,
  useRouteError,
} from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { find, cloneDeep } from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import axios from "axios";
import hash from "object-hash";

import {
  Home,
  SignUp,
  SignUpFinish,
  ResetPasswordStep1,
  ResetPasswordStep1Finish,
  ResetPasswordStep2,
  Agreement,
} from "./guest";
import { UpdateUser, UpdateEmail, UpdatePassword } from "./user";

import { VerticalCenterBox, TopMargin } from "./component";
import { MyAppBar } from "./appbar";
import { CreateProgramForm } from "./form";
import {
  ProgramCard,
  ActiveProgram,
  ViewProgram,
  ProgramMenu,
  MessageContainer,
  FileContainer,
  MemberContainer,
  AddMembersContainer,
  FormContainer,
} from "./program";
import Uploader from "./uploader";
import { yDate, requesterName, useLocalStorage, paramsFromUri } from "./module";
import { ProgramContext } from "./context";

const theme = createTheme({
  palette: {
    primary: {
      light: "#8bc769",
      main: "#6EBA44",
      dark: "#4d822f",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ef8aae",
      main: "#eb6d9a",
      dark: "#a44c6b",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Noto Sans JP"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const CreateProgramWrapper = styled.div`
  margin-bottom: 2rem;
`;
function CreateProgram() {
  const [account, ,] = useLocalStorage("account");
  return (
    <CreateProgramWrapper>
      <MyAppBar user={account.user} label="新しいプログラムを作成" />
      <TopMargin marginTop={1} />
      <VerticalCenterBox>
        <CreateProgramForm user={account.user} />
      </VerticalCenterBox>
    </CreateProgramWrapper>
  );
}

const PleaseDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  background-color: #fff;
  opacity: 0.5;
`;

const Surface = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const StyledHorizontalProgramList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 100%;
  background-color: rgb(52, 92, 104);

  @media (max-width: 767px) {
    display: none;
  }
`;

const ProgramTableWrapper = styled.div`
  padding: 2rem 1rem 0;

  @media (max-width: 767px) {
    display: none;
  }
`;

function ProgramTable(props) {
  const { user, programs } = props;
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <ProgramTableWrapper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>タイトル</TableCell>
              <TableCell>お名前</TableCell>
              <TableCell>作成日</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programs.map((p) => (
              <ProgramContext.Provider key={p.uuid} value={[p, undefined]}>
                <TableRow
                  onClick={() => {
                    navigate(`/programs/${p.uuid}/timelines${location.search}`);
                  }}
                >
                  <TableCell component="th" scope="row">
                    <ProgramMenu
                      user={user}
                      program={p}
                      notificationCntMap={{
                        messages: p[`messagesNotificationCount`],
                        materials: p[`materialsNotificationCount`],
                        deliveries: p[`deliveriesNotificationCount`],
                        members: p[`membersNotificationCount`],
                        form: p[`formNotificationCount`],
                      }}
                    />
                  </TableCell>
                  <TableCell>{p.title}</TableCell>
                  <TableCell>{requesterName(p)}</TableCell>
                  <TableCell>{yDate(p.createdAt)}</TableCell>
                </TableRow>
              </ProgramContext.Provider>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ProgramTableWrapper>
  );
}

const MobileCardList = styled.div`
  flex: 1 0 auto;
  padding-top: 1rem;
  background-color: #f5f5f5;

  @media (min-width: 768px) {
    display: none;
  }
`;

const FabWrapper = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  color: #fff;
`;

const BlackCoverBase = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
`;

const BlackCoverWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function BlackCover(props) {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/programs${location.search}`);
  };
  return (
    <BlackCoverWrapper>
      <BlackCoverBase onClick={handleClick} />
      {children}
    </BlackCoverWrapper>
  );
}

const HorizontalProgramItem = styled.div`
  overflow-y: auto;
  margin: 2rem 1rem 0.5rem;
  flex: 0 0 22rem;
`;

const FabWithStyle = styled(Fab)`
  color: #fff !important;
  background-color: #00b3cd !important;
`;

const useViewMode = (defaultValue) => {
  const [viewMode, setMode] = React.useState(defaultValue);

  function handleViewMode() {
    const newMode = viewMode === "list" ? "column" : "list";
    setMode(newMode);
  }

  return [viewMode, handleViewMode];
};

function BrowserProgramList(props) {
  const { programs, user, viewMode, navigate } = props;

  const handleProgram = (p) => {
    const newUrl = `/programs/${p.uuid}/timelines${window.location.search}`;
    navigate(newUrl);
  };

  if (viewMode === "column") {
    return (
      <StyledHorizontalProgramList>
        {programs.map((p) => (
          <HorizontalProgramItem
            key={p.uuid}
            onClick={() => {
              handleProgram(p);
            }}
          >
            <ViewProgramBoard program={p} user={user} />
          </HorizontalProgramItem>
        ))}
      </StyledHorizontalProgramList>
    );
  } else {
    return <ProgramTable programs={programs} user={user} />;
  }
}

const BrowserProgramListMemo = React.memo(BrowserProgramList);

function ProgramList() {
  const renderCnt = useRef(0);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const initialPrograms = useLoaderData();
  const [account, setAccount] = useLocalStorage("account");
  const [viewMode, handleViewMode] = useViewMode("column");
  const [programs, setPrograms] = useState(initialPrograms);

  const regex = /\/programs\/[a-z0-9-]+\/([a-z]+)/;
  const match = location.pathname.match(regex);

  let program = cloneDeep(find(programs, { uuid: params.uuid }));
  if (program) {
    if (match.length === 2) {
      const tab = match[1];
      if (tab !== "timelines") {
        program[`${tab}NotificationCount`] = 0;
      }
    }
  }

  const navigateMemo = useMemo(() => {
    return navigate;
  }, [null]);

  const fetchLatestPrograms = () => {
    axios
      .get("/api/programs", { params: paramsFromUri() })
      .then(function (response) {
        if (hash(programs) !== hash(response.data.programs)) {
          setPrograms(response.data.programs);
        }
      })
      .catch(function (err) {
        if (err.response && err.response.status === 401) {
          window.location.href = "/";
        }
      });
  };

  const windowFocusHandler = () => {
    fetchLatestPrograms();

    axios
      .get("/api/me")
      .then(function (response) {
        setAccount(response.data);
      })
      .catch(function (err) {
        if (err.response && err.response.status === 401) {
          window.location.href = "/";
        }
      });
  };

  const windowBlurHandler = () => {
    const controller = new AbortController();
    controller.abort();
  };

  useEffect(() => {
    window.addEventListener("focus", windowFocusHandler);
    window.addEventListener("blur", windowBlurHandler);
    return () => {
      window.removeEventListener("focus", windowFocusHandler);
      window.removeEventListener("blur", windowBlurHandler);
    };
  }, []);

  useEffect(() => {
    if (renderCnt.current > 0) {
      if (!match) {
        fetchLatestPrograms();
      }
    }
    renderCnt.current = renderCnt.current + 1;
  }, [location]);

  console.log("ProgramList");
  return (
    <Surface>
      <MyAppBar
        user={account.user}
        notifications={account.notifications}
        setAccount={setAccount}
        viewMode={viewMode}
        onViewMode={handleViewMode}
      />
      {isBrowser && (
        <BrowserProgramListMemo
          programs={programs}
          user={account.user}
          viewMode={viewMode}
          navigate={navigateMemo}
        />
      )}
      {isMobile && typeof program === "undefined" && (
        <MobileCardList>
          {programs.map((p) => (
            <ProgramContext.Provider key={p.uuid} value={[p, undefined]}>
              <ProgramCard user={account.user} program={p} />
            </ProgramContext.Provider>
          ))}
        </MobileCardList>
      )}
      {program && <Outlet context={[account.user, program]} />}
      <FabWrapper>
        <FabWithStyle
          aria-label="Add"
          onClick={() => {
            window.location.href = "/createProgram";
          }}
        >
          <AddIcon />
        </FabWithStyle>
      </FabWrapper>
    </Surface>
  );
}

const ProgramBoardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00b3cd;
  color: #fff;
  flex: 0 0 3rem;
  padding: 0 0.5rem;

  @media (max-width: 767px) {
    display: none;
  }
`;

const ProgramBoardMenu = styled.div`
  height: 44px;
  background-color: #8fc5cc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.75rem;
  flex: 0 0 auto;
`;

const ProgramRequesterDate = styled.div`
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 12px;
  height: 18px;
`;

const ProgramBoardFixedHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 600;

  @media (min-width: 768px) {
    top: 2.5%;
    width: 40rem;
  }
`;

const ProgramBoardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

function ViewProgramBoard(props) {
  const { user, program } = props;

  const notificationCntMap = {
    messages: program[`messagesNotificationCount`],
    materials: program[`materialsNotificationCount`],
    deliveries: program[`deliveriesNotificationCount`],
    members: program[`membersNotificationCount`],
    form: program[`formNotificationCount`],
  };

  return (
    <ProgramContext.Provider value={[program, undefined]}>
      <ProgramBoardWrapper>
        <ProgramRequesterDate>
          <span>{requesterName(program)}</span>
          <span>{yDate(program.createdAt)}</span>
        </ProgramRequesterDate>
        <ProgramBoardHeader>
          <Typography variant="body1" noWrap>
            {program.title}
          </Typography>
        </ProgramBoardHeader>
        <ProgramBoardMenu>
          <ProgramMenu
            user={user}
            program={program}
            notificationCntMap={notificationCntMap}
            baseColor="#fff"
            activeProgram={false}
          />
        </ProgramBoardMenu>
        <ViewProgram user={user} program={program} />
      </ProgramBoardWrapper>
    </ProgramContext.Provider>
  );
}

function ProgramBoard(props) {
  const { user, program } = props;
  const {
    getInputProps,
    handleFileDialog,
    uploadingFilesMessage,
    activeProgram = true,
  } = props;

  const [notificationCntMap, setNotificationCntMap] = useState({
    messages: program[`messagesNotificationCount`],
    materials: program[`materialsNotificationCount`],
    deliveries: program[`deliveriesNotificationCount`],
    members: program[`membersNotificationCount`],
    form: program[`formNotificationCount`],
  });

  return (
    <ProgramBoardWrapper>
      <ProgramBoardFixedHeader>
        <MyAppBar
          user={user}
          program={program}
          showAccountMenu={true}
          isProgramHeader={true}
        />
        <ProgramBoardMenu>
          <ProgramMenu
            user={user}
            program={program}
            notificationCntMap={notificationCntMap}
            setNotificationCntMap={setNotificationCntMap}
            baseColor="#fff"
            activeProgram={activeProgram}
          />
        </ProgramBoardMenu>
      </ProgramBoardFixedHeader>
      <ActiveProgram
        user={user}
        program={program}
        getInputProps={getInputProps}
        handleFileDialog={handleFileDialog}
        uploadingFilesMessage={uploadingFilesMessage}
        activeProgram
      />
    </ProgramBoardWrapper>
  );
}

const ActiveProgramBoardWrapper = styled.div`
  position: relative;
  z-index: 1000;
  overflow-y: auto;
  height: 100%;
  flex: 0 0 100%;
  @media (min-width: 768px) {
    flex: 0 0 40rem;
    height: 95%;
  }
`;

function ActiveProgramBoard() {
  const [user, initialProgram] = useOutletContext();
  const [program, setProgram] = useState(initialProgram);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  console.log("ActiveProgramBoard");

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uploader = new Uploader(
        program,
        acceptedFiles,
        (newUploadingFiles) => {
          setUploadingFiles(newUploadingFiles);
        }
      );

      if (acceptedFiles.length > 0) {
        setTimeout(() => {
          uploader.start().then(() => {
            window.location.reload();
          });
        }, 500);
      } else {
        window.alert(
          "選択されたファイルはアップロードできません。\n32G以内のファイルを選択してください。"
        );
      }
    },
    [program]
  );

  let uploadingFilesMessage;
  if (uploadingFiles.length > 0) {
    uploadingFilesMessage = {
      id: `uploadingFilesMessage-${new Date().toISOString()}`,
      user,
      type: "uploading",
      files: uploadingFiles,
    };
  }

  const {
    getRootProps,
    getInputProps,
    open: handleFileDialog,
    isDragAccept,
  } = useDropzone({
    maxSize: parseInt(process.env.REACT_APP_MAX_GIGABYTE) * 1024 * 1024 * 1024,
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <ProgramContext.Provider value={[program, setProgram]}>
      <BlackCover>
        <ActiveProgramBoardWrapper {...getRootProps()}>
          <ProgramBoard
            user={user}
            program={program}
            getInputProps={getInputProps}
            handleFileDialog={handleFileDialog}
            uploadingFilesMessage={uploadingFilesMessage}
            activeProgram={true}
          />
          {isDragAccept && <PleaseDrop>Drop files here to upload.</PleaseDrop>}
        </ActiveProgramBoardWrapper>
      </BlackCover>
    </ProgramContext.Provider>
  );
}

function ErrorPage() {
  const error = useRouteError();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.location.replace("/");
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "signUp",
    element: <SignUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: "signUpFinish",
    element: <SignUpFinish />,
    errorElement: <ErrorPage />,
  },
  {
    path: "updateUser",
    element: <UpdateUser />,
    errorElement: <ErrorPage />,
  },
  {
    path: "updateEmail",
    element: <UpdateEmail />,
    errorElement: <ErrorPage />,
  },
  {
    path: "updatePassword",
    element: <UpdatePassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "resetPasswordStep1",
    element: <ResetPasswordStep1 />,
    errorElement: <ErrorPage />,
  },
  {
    path: "resetPasswordStep1Finish",
    element: <ResetPasswordStep1Finish />,
    errorElement: <ErrorPage />,
  },
  {
    path: "resetPasswordStep2",
    element: <ResetPasswordStep2 />,
    errorElement: <ErrorPage />,
  },
  {
    path: "agreement",
    element: <Agreement />,
    errorElement: <ErrorPage />,
  },
  {
    path: "createProgram",
    element: <CreateProgram />,
    errorElement: <ErrorPage />,
  },
  {
    path: "programs/",
    element: <ProgramList />,
    errorElement: <ErrorPage />,
    loader: async () => {
      const response = await axios.get("/api/programs", {
        params: paramsFromUri(),
      });
      if (response.data) {
        return response.data.programs;
      }
    },
    children: [
      {
        path: ":uuid",
        element: <ActiveProgramBoard />,
        children: [
          { path: "timelines", element: <MessageContainer /> },
          { path: "messages", element: <MessageContainer /> },
          { path: "materials", element: <FileContainer /> },
          { path: "deliveries", element: <FileContainer /> },
          {
            path: "members",
            element: <MemberContainer />,
            loader: async ({ params }) => {
              const { uuid } = params;
              const response = await axios.get(`/api/programs/${uuid}/members`);
              if (response.data) {
                return response.data;
              } else {
                throw new Error();
              }
            },
            children: [{ path: "add", element: <AddMembersContainer /> }],
          },
          {
            path: "form",
            element: <FormContainer />,
            loader: async ({ params }) => {
              const { uuid } = params;
              const response = await axios.get(
                `/api/programs/${uuid}/formFields`
              );
              if (response.data) {
                return response.data;
              } else {
                throw new Error();
              }
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  console.log("App");
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
