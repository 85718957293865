import * as React from "react";

function SvgMail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15.235 12"
      {...props}
    >
      <path
        d="M0 0v12h15.235V0zm1.055 10.945v-9.89H13.4L7.667 5.629 2.95 2.024l-.684.9 5.387 4.1 6.527-5.159v9.064H1.055z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgMail;
