import axios from "axios";

export default class Uploader {
  constructor(program, baseFiles, callback) {
    this.program = program;
    this.baseFiles = baseFiles;
    this.callback = callback;

    this.children = this.baseFiles.map(
      (baseFile) => new FileUploader(program, baseFile, this.progressCallback)
    );

    this.progressCallback();
  }

  start() {
    const asyncFunc = async () => {
      const requestData = [];
      for (let i = 0; i < this.children.length; i++) {
        const child = this.children[i];
        await child.start();
        requestData.push({
          name: child.baseFile.name,
          sources: child.bigChanks.map((s) => s.getObjectId()),
        });
      }

      await axios.post(`/api/programs/${this.program.uuid}/files`, {
        files: requestData,
      });

      return true;
    };
    return asyncFunc();
  }

  progressCallback = () => {
    const newData = this.children.map((child) => {
      return {
        name: child.name,
        size: child.size,
        uploadedSize: child.uploadedSize,
        state: child.state,
      };
    });
    this.callback(newData);
  };
}

class FileUploader {
  static CHANK_SIZE = 67108864;

  constructor(program, baseFile, progressCallback) {
    this.program = program;
    this.baseFile = baseFile;

    let nUnit = parseInt(this.baseFile.size / FileUploader.CHANK_SIZE);
    if (this.baseFile.size > nUnit * FileUploader.CHANK_SIZE) {
      nUnit += 1;
    }
    this.nUnit = nUnit;

    this.progressCallback = progressCallback;

    this.name = baseFile.name;
    this.size = baseFile.size;
    this.uploadedSize = 0;
    this.state = 0;

    this.unique = Math.random().toString(36).substr(2, 5);

    this.bigChanks = [];
  }

  async start() {
    let chanks = [];

    for (let i = 0; i < this.nUnit; i++) {
      const order = i + 1;
      const start = i * FileUploader.CHANK_SIZE;
      let end = (i + 1) * FileUploader.CHANK_SIZE;
      if (end > this.baseFile.size) {
        end = this.baseFile.size;
      }
      const isLast = i === this.nUnit - 1;
      chanks.push(
        new Chank(
          this.program,
          this.baseFile,
          start,
          end,
          order,
          isLast,
          this.unique
        )
      );
    }

    const bigChanksLen = Math.ceil(chanks.length / 32);

    for (let i = 0; i < bigChanksLen; i++) {
      const order = i + 1;

      const startI = i * 32;
      const endI = startI + 32;
      const tmpChanks = chanks.slice(startI, endI);

      this.bigChanks.push(
        new BigChank(this.program, this.baseFile, order, this.unique, tmpChanks)
      );
    }

    chanks = [];

    for (let i = 0; i < bigChanksLen; i++) {
      const bigChank = this.bigChanks[i];
      for (let j = 0; j < bigChank.chanks.length; j++) {
        const chank = bigChank.chanks[j];
        await chank.send();
        this.uploadedSize += chank.getSize();
        this.state = parseInt((this.uploadedSize / this.size) * 100);

        this.progressCallback();
      }

      bigChank.chanks = [];

      await bigChank.send();
    }
  }
}

class BigChank {
  constructor(program, baseFile, order, unique, chanks) {
    this.program = program;
    this.baseFile = baseFile;
    this.order = order;
    this.unique = unique;

    this.chanks = chanks;
    this.sources = chanks.map((s) => s.getObjectId());
  }

  async send() {
    await axios.post(`/api/programs/${this.program.uuid}/chanks`, {
      name: this.baseFile.name,
      sources: this.sources,
      dest: this.getObjectId(),
    });
  }

  getObjectId() {
    return `${this.baseFile.name}_${this.unique}_${this.order}.bigpart`;
  }
}

class Chank {
  constructor(program, baseFile, start, end, order, isLast, unique) {
    this.program = program;
    this.baseFile = baseFile;
    this.start = start;
    this.end = end;
    this.order = order;
    this.isLast = isLast;
    this.reader = new FileReader();
    this.unique = unique;
  }

  read() {
    return new Promise((resolve, reject) => {
      this.reader.onabort = (e) => {
        console.log("file reading was aborted");
        reject(e);
      };
      this.reader.onerror = (e) => {
        console.log("file reading has failed");
        reject(e);
      };
      this.reader.onload = () => {
        resolve(this.reader.result);
      };

      const blob = this.baseFile.slice(this.start, this.end);
      this.reader.readAsArrayBuffer(blob);
    });
  }

  async send() {
    const buffer = await this.read();
    this.reader = null;

    const key = this.getObjectId();
    const params = new URLSearchParams();
    params.set("key", key);
    const urlResponse = await axios.get(
      `/api/programs/${this.program.uuid}/files/uploadUrl`,
      {
        params,
      }
    );
    const url = urlResponse.data;

    await fetch(url, {
      mode: "cors",
      method: "PUT",
      body: buffer,
    });
  }

  getSize() {
    return this.end - this.start;
  }

  getObjectId() {
    return `${this.baseFile.name}_${this.unique}_${this.order}.part`;
  }
}
