import React from "react";
import Typography from "@mui/material/Typography";
import styled from '@emotion/styled'

import Logo from "./logo.png";
import Logo2 from "./logo2.png";
import {
  Paragraph,
  VerticalCenterBox,
  LogoImg,
  PageTitle,
  Footer,
} from "./component";
import {
  SignInForm,
  SignUpForm,
  ResetPasswordStep1Form,
  ResetPasswordStep2Form,
} from "./form";

const GuestSurface = styled.div`
  height: 100%;
  @media (min-width: 768px) {
    background-color: #f5f5f5;
  }
`;

const HomeWrapper = styled.div`
  padding-top: 5rem;
`;

const TopLogoImg = styled.img`
  width: 92%;
  margin-top: 1.2rem;
  @media (min-width: 768px) {
    width: 28rem;
  }
`;

export function Home() {
  return (
    <GuestSurface>
      <HomeWrapper>
        <VerticalCenterBox>
          <PageTitle>ネットで簡単！オンラインポスプロ</PageTitle>
          <TopLogoImg src={Logo} alt="" />
          <SignInForm />
        </VerticalCenterBox>
        <Footer />
      </HomeWrapper>
    </GuestSurface>
  );
}

const SignUpWrapper = styled.div`
  padding-top: 2rem;
`;
export function SignUp() {
  return (
    <GuestSurface>
      <SignUpWrapper>
        <VerticalCenterBox>
          <LogoImg src={Logo2} />
          <PageTitle>アカウント作成</PageTitle>
          <SignUpForm />
        </VerticalCenterBox>
        <Footer />
      </SignUpWrapper>
    </GuestSurface>
  );
}

const SignUpFinishWrapper = styled.div`
  padding-top: 2rem;
`;
export function SignUpFinish() {
  return (
    <GuestSurface>
      <SignUpFinishWrapper>
        <VerticalCenterBox>
          <LogoImg src={Logo2} />
          <PageTitle>メールアドレス認証</PageTitle>
          <Paragraph>
            アカウント作成ありがとうございます。
            <br />
            ご入力いただいたメールアドレスに認証のURLを送信しました。
            <br />
            クリックして認証を完了させてください。
          </Paragraph>
        </VerticalCenterBox>
        <Footer />
      </SignUpFinishWrapper>
    </GuestSurface>
  );
}

const ResetPasswordStep1Wrapper = styled.div`
  padding-top: 2rem;
`;
export function ResetPasswordStep1() {
  return (
    <GuestSurface>
      <ResetPasswordStep1Wrapper>
        <VerticalCenterBox>
          <LogoImg src={Logo2} />
          <PageTitle>パスワード再発行</PageTitle>
          <Paragraph>
            ご登録のメールアドレスに再発行用のURLを送信します。
          </Paragraph>
          <ResetPasswordStep1Form />
        </VerticalCenterBox>
        <Footer />
      </ResetPasswordStep1Wrapper>
    </GuestSurface>
  );
}

const ResetPasswordStep1FinishWrapper = styled.div`
  padding-top: 2rem;
`;
export function ResetPasswordStep1Finish() {
  return (
    <GuestSurface>
      <ResetPasswordStep1FinishWrapper>
        <VerticalCenterBox>
          <LogoImg src={Logo2} />
          <PageTitle>メールアドレス認証</PageTitle>
          <Paragraph>
            ご入力いただいたメールアドレスに再発行用のURLを送信しました。 <br />
            クリックして再発行を完了させてください。
          </Paragraph>
        </VerticalCenterBox>
        <Footer />
      </ResetPasswordStep1FinishWrapper>
    </GuestSurface>
  );
}

const ResetPasswordStep2Wrapper = styled.div`
  padding-top: 2rem;
`;
export function ResetPasswordStep2() {
  return (
    <GuestSurface>
      <ResetPasswordStep2Wrapper>
        <VerticalCenterBox>
          <LogoImg src={Logo2} />
          <PageTitle>パスワード再発行</PageTitle>
          <Paragraph>新しいパスワードを入力してください。</Paragraph>
          <ResetPasswordStep2Form />
        </VerticalCenterBox>
        <Footer />
      </ResetPasswordStep2Wrapper>
    </GuestSurface>
  );
}

const AgreementDate = styled.div`
  width: 95%;
  text-align: right;
  font-size: 0.75rem;
  color: #707070;
`;

const AgreementBody = styled.div`
  width: 95%;
`;
const AgreementWrapper = styled.div`
  padding-top: 2rem;
`;
export function Agreement() {
  return (
    <GuestSurface>
      <AgreementWrapper>
        <VerticalCenterBox sidePadding={2}>
          <LogoImg src={Logo2} />
          <PageTitle>利用規約</PageTitle>
          <AgreementDate>
            <Paragraph>2021年1月8日 改定</Paragraph>
          </AgreementDate>
          <AgreementBody>
            <Paragraph>
              この規約（以下「本規約」といいます。）には、サービスの提供条件及び有限会社サルミックス(以下弊社という)と登録ユーザーの皆様（以下「ユーザー」といいます）との間の権利義務関係が定められています。なお、本規約については、お客様が利用登録した時点で同意されたものとさせていただきますので、ご利用の前に必ずお読みください。
            </Paragraph>
            <Typography variant="h6" noWrap>
              1. SALITのサービスについて
            </Typography>
            <Paragraph>
              本サービスは、インターネットを通じて映像・音響に関するポストプロダクション業務を行うサービス（以下「本サービス」といいます）になります。
            </Paragraph>
            <Typography variant="h6" noWrap>
              2. 規約の受諾
            </Typography>
            <Paragraph>
              弊社は本規約に従うことにより、本サービスを提供します。本サービスをご利用になった時点で、ユーザーは本規約に同意を得たものとみなします。また、弊社が本サイトに掲載の上、提供する内容は本規約の一部として構成されます。
            </Paragraph>
            <Typography variant="h6" noWrap>
              3. サービスの利用登録について
            </Typography>
            <Paragraph>
              １．本サービスをご利用いただくには、会員登録を行う必要があります。利用希望者は新規会員登録のユーザー登録画面より、フォームに従って必要項目の入力が必要になります。万が一、虚偽の情報を入力したことが判明した場合は、弊社は予告なく本サービスに登録されたユーザーのログイン情　報を削除し、それ以降再び本サービスの利用を拒否する権利を有します。
            </Paragraph>
            <Paragraph>
              ２．弊社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
              （１）弊社に提供した登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
              （２）反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営
              若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると弊社が判断した場合
              （３）登録希望者が過去弊社との契約に違反した者またはその関係者であると弊社が判断した場合
              （４）その他、弊社が登録を適当でないと判断した場合
            </Paragraph>
            <Paragraph>
              ３．登録ユーザーは、登録事項に変更があった場合、弊社の定める方法により当該変更事項を退滞なく弊社に通知するものとします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              4. 登録メールアドレスとパスワードの管理
            </Typography>
            <Paragraph>
              本サービス利用に必要なログイン情報(メールアドレス・パスワード等)は自らの責任で管理を行ってください。ログイン情報の漏洩などによって生じたいかなる損害も、弊社で補償することはありません。また、万が一、ご自身のログイン情報が第三者に漏洩・不正利用された場合は、速やかに弊社までご連絡下さい。※
              本サービスのご利用を終了される際には、その都度ログアウトをしてください。
            </Paragraph>
            <Typography variant="h6" noWrap>
              5. ユーザーの責務
            </Typography>
            <Paragraph>
              ユーザーは本サービスを介して、原則として送受信したファイルに関する一切の責任を負うものとします。本サービスを介して送受信したファイルを利用する際は、内容の信頼性、正確性、完成度、有用性、ウイルスの有無についてユーザー自身で判断し、ユーザーの責任とリスク負担のもとで利用するものとします。
            </Paragraph>
            <Paragraph>
              ユーザー又はユーザーの共有メンバーは納品データの取り扱いには注意してください。それによって情報が漏洩しても弊社は責任を負わないものとします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              6. 本サービス利用における禁止及び制約事項
            </Typography>
            <Paragraph>
              ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為又は該当すると弊社が判断する行為をしてはなりません。また、万が一、弊社が該当する行為を発見した場合は、ユーザーに通知することなく削除いたします。
              （１）本サービスは弊社に発注するプログラム以外にはご使用できません
              （２）法令に違反する行為又は犯罪行為に関連する行為
              （３）弊社、本サービスの他のユーザー又はその他の第三者に対する詐欺又は脅迫行為
              （４）公序良俗に反する行為
              （５）当社、本サービスの他のユーザー又はその他の第三者の知的財産権、肖像権、プライバシーの　権利、名誉、その他の権利又は利益を侵害する行為
              （６）本サービスのネットワークまたはシステム等に妨害したり混乱させたり等過度な負荷をかける行為
              （７）本サービスの運営を妨害するおそれのある行為
              （８）弊社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為
              （９）第三者に成りすます行為
              （１０）本サービスの他のユーザーのIDまたはパスワードを利用する行為
              （１１）弊社、本サービスの他のユーザー又はその他の第三者に不利益、損害、不快感を与える行為
              （１２）反社会的勢力等への利益供与
              （１３）本サービスを通じて配信されたメールの配信元を隠したり、偽装する行為
              （１４）その他、弊社が不適切と判断する行為
            </Paragraph>
            <Typography variant="h6" noWrap>
              7. 免責事項
            </Typography>
            <Paragraph>
              １．弊社はサービスの提供において、支障が出ないようスムーズな運用に最善の努力義務を担いますが、以下の場合においては、弊社の重過失が認められない限り、弊社は責任を負わないものとします。
              （１）通信回線やコンピュータなどの障害によるシステムの中断、遅延、中止、データの消去、またはデータへの不正アクセスによりユーザー側に生じた損害
              （２）セキュリティ施策にも関わらす、第３者によるクラッキング行為等により、ユーザーに損害が生じた場合
              （３）停電、火災、戦争、地震、労働紛争など弊社の責に帰すべからざる事由により本サービスの提供が　困難な場合における損害
              （４）郵送事故による故障・損害・紛失
              （５）その他、各条項に定められている免責について
            </Paragraph>
            <Paragraph>
              ２．弊社は、本サービスに関して、ユーザーとユーザーが共有したメンバー又は第三者との間において生じた取引、連絡又は紛争等について一切の責任を負いません。
            </Paragraph>
            <Typography variant="h6" noWrap>
              8. 作業内容
            </Typography>
            <Paragraph>
              ユーザーからの作業依頼について、技術的・予算・その他弊社の都合によりお受けできない場合があります。ユーザーは弊社との間で本サービスに関する取引の内容・条件等に合意した時点で、ユーザーと弊社との間で本規約の諸規定に従った個別具体的な利用契約が成立するものとします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              9. 納期
            </Typography>
            <Paragraph>
              納期はユーザーとの相談の上、作業内容に応じた無理のない制作期間を設定します。納期希望日に応じることができない場合もあります。
              完成ファイルについては、納期内に納品できるよう最善を尽くすように努めますが、予期せぬ事故・都合等により納期が変動する場合もあります。また、ユーザー側の都合による納期の遅れにつきましては、いかなる損害も弊社は一切責任を負わないものとします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              10. プライバシーポリシー
            </Typography>
            <Paragraph>
              ユーザーによる本サービスの利用に関連して弊社が知り得るユーザーの情報の管理および取扱いについては、弊社が別途定めるプライバシーポリシーによるものとします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              11. ファイルの取扱い、やり取りについて
            </Typography>
            <Paragraph>
              本サービスを利用してアップロードできるファイル(素材)の容量は、１回の送信において32GBまでとします。
              またファイルの保存期間は、取引終了までとします。但し、ユーザーが本規約に違反した場合や弊社独自の判断により期間内であってもファイルを削除することができるものとします。一度削除されたファイルは復元できません。データ消去による損害責任について弊社は一切責任を負わないものとします。データのお預かり期間は作業終了後、最低3ヵ月となります。
            </Paragraph>
            <Typography variant="h6" noWrap>
              12. 連絡／通知
            </Typography>
            <Paragraph>
              営業時間外のレスポンスは翌営業日になる場合があります。(平日9：00～18：00)弊社に不備がない修正や作業開始後の変更により発生した作業は追加料金が発生します。
            </Paragraph>
            <Typography variant="h6" noWrap>
              13. ファイル、ユーザー情報の削除
            </Typography>
            <Paragraph>
              弊社は、ユーザーが本規約に違反した場合、または、弊社、ユーザーもしくは第三者の権利利益を保護するため、その他本サービスの適正な運営のため弊社が必要と判断した場合には、本サービスを提供するサーバー上に保存されているファイルを削除する事ができるものとします。また、ユーザー情報についても同様とします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              14. 変更とキャンセル
            </Typography>
            <Paragraph>
              弊社からの見積もり内容について、第８条（作業内容）にて個別具体的な利用契約が成立した以降の変更・キャンセルにつきましてはキャンセル料が発生します。
            </Paragraph>
            <Typography variant="h6" noWrap>
              15. 支払い方法
            </Typography>
            <Paragraph>
              すでに弊社と支払期日についての取り決めがある場合を除き、作業終了後３０日以内で弊社所定の振込み先口座にお振込み下さい。作業が完了した月の月末締めになります。
            </Paragraph>
            <Typography variant="h6" noWrap>
              16. 未払いについて
            </Typography>
            <Paragraph>
              支払い期限を過ぎた未払いの利用料金等がある場合、ユーザーは弊社に対し、直ちに当該利用料金等を支払う義務を負います。また、未払いの利用料金がある場合には、本サービスの利用を一時停止または解除することができるものとします。(利用停止、解除後も未払いの利用料金を支払う義務を負うものとします。)
            </Paragraph>
            <Typography variant="h6" noWrap>
              17. サービス内容の変更、中断、停止
            </Typography>
            <Paragraph>
              弊社は事前の通知なしに本サービスの一部または全部を変更・中断・停止することができます。弊社はユーザーに対して、本サービスの変更・中断・停止に関する一切の責任を負うことはありません。
            </Paragraph>
            <Typography variant="h6" noWrap>
              18. 規約の変更
            </Typography>
            <Paragraph>
              １．弊社は以下の場合に、弊社の裁量により本規約を変更することができます。
              （１）本規約の変更が、ユーザーの一般の利益に適合するとき
              （２）本規約の変更が、契約した目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき
            </Paragraph>
            <Paragraph>
              ２．弊社は前項による本規約の変更にあたり、変更後の本規約の効力発生日の1か月前までに利用規約を変更する旨及び変更後の利用規約の内容とその効力発生日を本サービスより掲示し、またはユーザーに電子メールで通知するものとします。
            </Paragraph>
            <Paragraph>
              ３．変更後の利用規約の効力発生日以降にユーザーが本サービスを利用したときは、ユーザーは、利用規約の変更に同意したものとみなします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              19.秘密保持
            </Typography>
            <Paragraph>
              1.　ユーザーおよび弊社は、技術上又は営業上の情報、及び内容その他一切の情報を相手方が書面により秘密である旨指定して開示した情報、または口頭により秘密である旨を示して開示した情報であって、開示後書面により内容を特定した上で秘密である旨通知した情報（以下あわせて「秘密情報」といいます。）を第三者に開示または漏洩してはならないものとします。但し、次の各号のいずれか一つに該当する情報は秘密情報に該当しません。
              ①秘密保持義務を負うことなく既に保有している情報
              ②秘密保持義務を負うことなく第三者から正当に入手した情報
              ③相手方から提供を受けた情報によらず、独自に開発した情報
              ④利用契約に違反することなく入手し、かつ、その受領の前後を問わず公知となった情報
            </Paragraph>
            <Paragraph>
              2.　秘密情報の提供を受けた当事者は、当該秘密情報の管理に必要な措置を講ずるものとします。
            </Paragraph>
            <Paragraph>
              3.　ユーザーおよび弊社は、秘密情報について、利用契約の目的の範囲内でのみ使用し、利用契約の目的の範囲を超える複製または改変が必要なときは、事前に相手方から書面による承諾を受けるものとします。
            </Paragraph>
            <Paragraph>
              4.　ユーザーおよび弊社は、秘密情報を、利用契約の目的を遂行するために業務上必要とする役員および従業員に限り開示するものとし、利用契約に基づきお客様および弊社が負担する秘密保持義務と同等の義務を、秘密情報の開示を受けた当該役員および従業員に退職後も含め課すものとします。
            </Paragraph>
            <Paragraph>
              5.　本条の規定は、利用契約終了後も有効に存続します。
            </Paragraph>
            <Paragraph>
              6．納品データのURLは信頼できる相手以外には教えないようお願いします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              20. 知的財産権
            </Typography>
            <Paragraph>
              本サービス、及び当サイト上に掲載している個々の文章・デザイン・商標・ロゴマーク等についての著作権・商標権・その他知的財産権は弊社に帰属します。ユーザーは著作権・商標権その他の知的財産権を侵害しないことに同意するものとします。
              （・特許権、商標権、企業秘密、著作権、言論権、知的所有権、その他の権利を侵害するコンテンツ　を送信・発信または利用する、もしくは、その恐れのある行為）
            </Paragraph>
            <Typography variant="h6" noWrap>
              21.権利帰属
            </Typography>
            <Paragraph>
              １．ユーザーはアップロードするデータについて、自らが権利を有しているか権利元に複製・改変する許可を得ていることについて、弊社に対し表明し、保証するものとします。
            </Paragraph>
            <Paragraph>
              ２．ユーザーは、弊社及び弊社から権利を承継したまたは許諾された者に対して著作者人格権を行使し　ないことに同意するものとします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              22. 利用規約条項の分離適用
            </Typography>
            <Paragraph>
              本規約の一部に不備があった場合でも、その条項については可能な限り最大限行使されます。また、他の条項は効力を失わないものとします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              23. サービスの転売
            </Typography>
            <Paragraph>
              本サービスの全部または一部を複製したり、販売(再販を含む)したりする事を禁止します。但し、別途書面等において契約を行った場合は除きます。
            </Paragraph>
            <Typography variant="h6" noWrap>
              24. 準拠法と管轄裁判所
            </Typography>
            <Paragraph>
              本規約及びサービス利用契約の準拠法は日本法とします。また、本サービスまたは本規約に関連して弊社とユーザーの間に紛争が生じた場合は、東京地方裁判所を管轄裁判所とします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              25. 協議
            </Typography>
            <Paragraph>
              本規約に定めていない事項については、信義誠実の原則で協議解決するものとします。
            </Paragraph>
            <Typography variant="h6" noWrap>
              26. 利用規約違反について
            </Typography>
            <Paragraph>
              本規約違反に関するお問い合わせは「info@salmix.co.jp」までご連絡ください。
            </Paragraph>
          </AgreementBody>
        </VerticalCenterBox>
        <Footer />
      </AgreementWrapper>
    </GuestSurface>
  );
}
