import { useState, useEffect } from "react";

export function yDate(dateStr) {
  const dt = new Date(dateStr);
  dt.setHours(dt.getHours() + 9);
  return dt.toISOString().slice(2, 10).replaceAll("-", ".");
}

export function mDate(dateStr) {
  const dt = new Date(dateStr);
  dt.setHours(dt.getHours() + 9);
  const date = dt.toISOString().substring(5, 10).replace("-", "/");
  const time = dt.toISOString().substring(11, 16);
  return `${date} ${time}`;
}

export function organizationName(u) {
  if (u.organization) {
    return `${u.organization} ${u.name}`;
  } else {
    return u.name;
  }
}

export function addressTel(u) {
  let s = "";
  if (u.address) {
    s += `${u.address} `;
  }
  if (u.tel) {
    s += `${u.tel}`;
  }
  return s;
}

export function requesterName(p) {
  let requester = "";
  if (p.requesterName && p.requesterOrganization) {
    requester = `${p.requesterOrganization}／${p.requesterName}`;
  } else if (p.requesterName) {
    requester = p.requesterName;
  }
  return requester;
}

export function nl2br(str, is_xhtml) {
  if (typeof str === "undefined" || str === null) {
    return "";
  }
  var breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
}

function getStorageValue(key, defaultValue) {
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export const useLocalStorage = (key, defaultValue = {}) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const paramsFromUri = () => {
  const insert = {};

  const urlParams = new URL(window.location).searchParams;
  if (urlParams.has("q")) {
    insert.qQ = urlParams.get("q");
  }
  if (urlParams.has("who")) {
    insert.qWho = urlParams.get("who");
  }
  if (urlParams.has("status")) {
    insert.qStatus = urlParams.get("status");
  }
  if (urlParams.has("mine")) {
    insert.qMine = true;
  }
  if (urlParams.has("archived")) {
    insert.qArchived = true;
  }

  return insert;
};
